import { LuckyGameCard } from 'features/games';
import { Button, Modal, useBuyLuckyGameAccess, useLuckyGames, WithLoadingWrapper } from 'shared';
import { ButtonVariants } from 'shared/constants/button';

import { LuckyGamesSkeletonContainer } from '../../skeleton';

export const Games = () => {
  const { games, isGamesLoading } = useLuckyGames();

  const {
    isLoading: isBuyingAccess,
    isModalBuyOpen,
    handleOpenBuyModal,
    handleBuyLuckyGameAccess,
    handleCloseBuyModal
  } = useBuyLuckyGameAccess();

  return (
    <>
      <Modal
        header="Are you sure you want to join this lucky game?"
        isOpened={isModalBuyOpen}
        handleClose={handleCloseBuyModal}
        height={40}
      >
        <div className="h-full flex items-end">
          <Button
            variant={ButtonVariants.SECONDARY}
            isLoading={isBuyingAccess}
            onClick={handleBuyLuckyGameAccess}
            className="mb-6"
          >
            Join
          </Button>
        </div>
      </Modal>
      <div className="space-y-1.5 h-full">
        <WithLoadingWrapper
          isLoading={isGamesLoading}
          skeleton={<LuckyGamesSkeletonContainer />}
          data={games}
          renderContent={(game) => (
            <LuckyGameCard key={game.id} handlePlayGame={handleOpenBuyModal} {...game} />
          )}
          emptyContent="There are currently no available lucky games"
        />
      </div>
    </>
  );
};
