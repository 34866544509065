import { useEffect, useState } from 'react';

import { differenceInMilliseconds } from 'date-fns';

import {
  asyncGetLeaderboard,
  getTelegramApi,
  selectLeaderboardType,
  selectTimeoutRequest,
  useAppDispatch,
  useAppSelector
} from 'shared';
import { Tabs } from 'shared/components/Tabs';
import { LeaderboardType } from 'shared/types/query.interfaces';

import { LeaderboardBlockContent } from './LeaderboardBlockContent';

const TABS = [
  {
    id: LeaderboardType.POINTS,
    title: 'Coins',
    component: () => <LeaderboardBlockContent typeLeaderboard={LeaderboardType.POINTS} />
  },
  {
    id: LeaderboardType.FRIENDS,
    title: 'Friends',
    component: () => <LeaderboardBlockContent typeLeaderboard={LeaderboardType.FRIENDS} />
  }
];

export const LeaderboardBlock = () => {
  const dispatch = useAppDispatch();

  const timeoutRequest = useAppSelector(selectTimeoutRequest);
  const currentLeaderboardType = useAppSelector(selectLeaderboardType);

  const [leaderboardType, setLeaderboardType] = useState<LeaderboardType>(LeaderboardType.POINTS);

  const tg = getTelegramApi();
  tg.BackButton.isVisible = true;

  const handleChangeLeaderboardType = (_: number, tabId: string) => {
    setLeaderboardType(tabId as LeaderboardType);
  };

  useEffect(() => {
    const now = new Date();

    const differenceSecond = Math.floor(
      differenceInMilliseconds(now, timeoutRequest ? timeoutRequest : 1000) / 1000
    );

    const minutes = Math.floor((differenceSecond % 3600) / 60);

    if (!timeoutRequest || currentLeaderboardType !== leaderboardType || minutes > 10)
      dispatch(asyncGetLeaderboard({ limit: 10, page: 0, type: leaderboardType }));

    return;
  }, [dispatch, leaderboardType]);

  return (
    <div className="flex flex-col gap-4 font-bold">
      <div className="flex flex-col items-center ">
        <h2 className="text-2xl">LEADERBOARDS</h2>
      </div>

      <Tabs onTabClick={handleChangeLeaderboardType} tabs={TABS} contentClassName="h-fit" />
    </div>
  );
};
