import { ReactNode } from 'react';

import { useParams } from 'react-router-dom';

import { ReactComponent as TicketIcon } from 'assets/svg/ticket.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/trash-can.svg';
import { Button, LotteryClearCombinationPayload, LuckyCombination } from 'shared';
import { Typography } from 'shared/components/Typography';
import { ButtonVariants } from 'shared/constants/button';
import { TypographyVariants } from 'shared/constants/typography';

interface Props extends LuckyCombination {
  position: number;
  children: ReactNode;
  handleClear: (values: LotteryClearCombinationPayload) => void;
}

const LuckyGameCombinationsCard = ({
  position,
  children,
  handleClear,
  id: combinationId,
  selected_combination_numbers: selectedCombinationsNumbers
}: Props) => {
  const { id } = useParams();

  const onClear = () => {
    if (!id) return;

    const isCombinationEmpty = selectedCombinationsNumbers.every(
      (combinationNumber) => !combinationNumber
    );

    if (isCombinationEmpty) return;

    handleClear({ id, combinationId });
  };

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <div className="flex justify-between items-center space-x-2 w-12">
          <Typography className="text-right" variant={TypographyVariants.MEDIUM_TITLE}>
            {position}
          </Typography>
          <TicketIcon />
        </div>
        {children}
      </div>
      <div className="flex items-center">
        <Button variant={ButtonVariants.TRANSPARENT} onClick={onClear}>
          <DeleteIcon />
        </Button>
      </div>
    </div>
  );
};

export default LuckyGameCombinationsCard;
