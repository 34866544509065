export const GRAVITY = 0.25;

export const JUMP = -6;

export const BIRD_SIZE = 30;

export const OBSTACLE_WIDTH = 50;

export const OBSTACLE_GAP = 300;

export const TUBES_GAP = 120;

export const GAME_HEIGHT = 600;

export const GAME_WIDTH = window.innerWidth - 58;
