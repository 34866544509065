import cn from 'classnames';

import { Button } from 'shared';
import { Typography } from 'shared/components/Typography';
import { ButtonVariants } from 'shared/constants/button';
import { GameStatus, variantGameStatus } from 'shared/constants/games';

interface Props {
  heading?: string;
  buttonText?: string;
  status?: GameStatus;
  blockClassName?: string;
  handleClick: VoidFunction;
}

export const GameStatusModal = ({
  heading,
  buttonText,
  status = GameStatus.DEFAULT,
  blockClassName,
  handleClick
}: Props) => {
  const title = heading || variantGameStatus[status].heading;
  const btnText = buttonText || variantGameStatus[status].buttonText;

  return (
    <div
      className={cn(
        'absolute top-0 left-0 z-[10000] w-full h-full backdrop-blur-sm',
        variantGameStatus[status].blockClassName,
        blockClassName
      )}
    >
      <div className="flex flex-col justify-center items-center h-full gap-4 rounded-md animate-bump">
        <Typography tag="h2" className="text-3xl text-white">
          {title}
        </Typography>
        <Button
          variant={ButtonVariants.PRIMARY}
          onClick={handleClick}
          className="py-2 shadow-inner text-lg"
        >
          {btnText}
        </Button>
      </div>
    </div>
  );
};
