import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app';

const selectState = (x: RootState) => x.userReducer;

export const selectUser = createSelector(selectState, ({ user }) => user);

export const selectIsAuth = createSelector(selectState, ({ isAuth }) => isAuth);

export const selectServerTime = createSelector(selectState, ({ serverTime }) => serverTime);

export const selectIsUserLoading = createSelector(selectState, ({ isLoading }) => isLoading);

export const selectIsFirstLoading = createSelector(
  selectState,
  ({ isFirstLoading }) => isFirstLoading
);

export const selectIncomeAutoBot = createSelector(
  selectState,
  ({ autoBotIncome }) => autoBotIncome
);

export const selectClaimedBalance = createSelector(
  selectState,
  ({ user }) => user?.claimed_balance || null
);
