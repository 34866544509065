import cn from 'classnames';

import { UserBalance, UserCard } from 'entities/user';
import { ActionsBar } from 'features/actions-bar';
import RewardsBlock from 'features/rewards/components/RewardsBlock';
import { Modal, selectIsFirstLoading, selectUser, useAppSelector, useBoolean } from 'shared';
import { Loader } from 'shared/components/Loader';
import { ButtonClaim } from 'widgets/button-claim';

export const HomeBlock = () => {
  const user = useAppSelector(selectUser);

  const isLoading = useAppSelector(selectIsFirstLoading);

  const [isDailyRewardModalOpen, handleOpenDailyRewardModal, handleCloseDailyRewardModal] =
    useBoolean();

  if (!user) return <Loader />;

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-8">
          <Modal
            isOpened={isDailyRewardModalOpen}
            handleClose={handleCloseDailyRewardModal}
            header="Daily Reward"
            description="Don't miss a day to keep your progress on track"
          >
            <RewardsBlock />
          </Modal>
          <UserCard
            className={cn('-translate-y-10 opacity-0 duration-200 ease-linear', {
              'opacity-100 translate-y-0': isLoading
            })}
            userName={user.username ?? user.first_name}
            userImageUrl={user.avatar}
          />
          <UserBalance
            className={cn('-translate-x-10 opacity-0 duration-300 ease-linear', {
              'opacity-100 translate-x-0': isLoading
            })}
            balance={user.claimed_balance}
          />
          <ButtonClaim
            className={cn('translate-x-10 opacity-0 duration-300 ease-linear', {
              'opacity-100 translate-x-0': isLoading
            })}
            lastClaim={user.last_claim}
            userId={user.id}
          />
        </div>
        <ActionsBar user={user} handleClick={handleOpenDailyRewardModal} />
      </div>
    </>
  );
};
