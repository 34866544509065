import { ButtonHTMLAttributes, FC, ReactNode, MouseEvent } from 'react';

import { twMerge } from 'tailwind-merge';

import { LoaderSizes } from 'shared/types/loader.interfaces';
import { SVGComponent } from 'shared/types/svg.interfaces';

import { Loader } from './Loader';
import { ButtonVariants, ButtonVariantsStyles } from '../constants/button';
import { handleHapticFeedback } from '../lib/utils/telegram';
import { HapticFeedbackStyles } from '../types/telegram.interfaces';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  loaderClassName?: string;
  loaderSizes?: LoaderSizes;
  variant?: ButtonVariants;
  hapticStyle?: HapticFeedbackStyles;
  isLoading?: boolean;
  icon?: SVGComponent;
  iconClassName?: string;
}

export const Button: FC<Props> = ({
  children,
  icon: Icon,
  iconClassName,
  className,
  loaderClassName,
  loaderSizes,
  isLoading,
  disabled,
  variant = ButtonVariants.PRIMARY,
  hapticStyle = HapticFeedbackStyles.LIGHT,
  onClick,
  ...props
}) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    handleHapticFeedback(hapticStyle);
    onClick?.(e);
  };

  return (
    <button
      type="button"
      className={twMerge(
        'w-fit border-none relative flex items-center justify-center rounded-lg transition-all disabled:pointer-events-none overflow-hidden',
        ButtonVariantsStyles[variant],
        className
      )}
      disabled={isLoading || disabled}
      onClick={handleClick}
      {...props}
    >
      {isLoading ? (
        <Loader size={loaderSizes} className={loaderClassName} />
      ) : (
        <>
          {Icon && <Icon className={twMerge('w-3 h-3', iconClassName)} />}
          {children}
        </>
      )}
    </button>
  );
};
