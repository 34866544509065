import { LotteryGameResults } from 'features/lottery-game-results';

import { Typography } from '../shared';
import { Tabs } from '../shared/components/Tabs';
import { TypographyVariants } from '../shared/constants/typography';
import { Tab } from '../shared/types/tabs.interfaces';
import { Games } from '../widgets/games';

const GAMES_TABS: Tab[] = [
  {
    id: 'activeGames',
    title: 'Choose game',
    component: () => <Games />
  },
  {
    id: 'gameResults',
    title: 'Game results',
    component: () => <LotteryGameResults />
  }
];

export const LuckyGames = () => {
  // TODO - create common layout with title(for pages)
  return (
    <div className="space-y-4">
      <Typography className="text-center" tag="h1" variant={TypographyVariants.LARGE_TITLE}>
        LUCKY GAMES
      </Typography>

      <Tabs tabs={GAMES_TABS} contentClassName="h-fit" />
    </div>
  );
};
