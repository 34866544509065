import { AxiosRequestConfig } from 'axios';

import { GamesPayload } from 'shared/types/games.interfaces';

import { httpApiClient } from '../config/http-client.configs';

export class GamesApi {
  constructor(private url: string) {}

  getGames(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ games: GamesPayload[] }>(`${this.url}`, config);
  }
}

export const gamesApi = new GamesApi('/game');
