import { AxiosRequestConfig } from 'axios';

import { GameClaimRewardPayload } from 'shared/types/games.interfaces';

import { httpApiClient } from '../config/http-client.configs';
import { ClaimDailyRewardPayload } from '../types/rewards.interfaces';
import { BalancePayload, ClaimPayload, User } from '../types/users.interfaces';

export class UsersApi {
  constructor(private url: string) {}

  getUser(payload: User, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ user: User; serverTime: string }>(`${this.url}`, payload, config);
  }

  claimTokens({ ...payload }: ClaimPayload, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ user: User }>(`${this.url}/claim-tokens`, payload, config);
  }

  claimDailyReward({ rewardType }: ClaimDailyRewardPayload, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ user: User }>(
      `${this.url}/claim-daily`,
      { type: rewardType },
      config
    );
  }

  updateBalance({ ...payload }: BalancePayload, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ user: User }>(`${this.url}/update-balance`, payload, config);
  }

  autoBotClaimTokens(config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ user: User; income: number }>(`${this.url}/autobot-claim`, config);
  }

  gameClaimReward({ ...payload }: GameClaimRewardPayload, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ user: User }>(`${this.url}/game-claim-reward`, payload, config);
  }
}

export const usersApi = new UsersApi('/user');
