import React, { useEffect } from 'react';

import { ReactComponent as ClearSvg } from 'assets/svg/clear.svg';
import { ReactComponent as PlusSvg } from 'assets/svg/plus.svg';
import { ReactComponent as RandomSvg } from 'assets/svg/random.svg';
import {
  Button,
  LUCKY_GAME_TYPES,
  LuckyCombination,
  Modal,
  SwitchableInput,
  SwitchableInputsGroup,
  useBoolean,
  useRandomNumbersWithIterations,
  useSetLuckyGameCombinations,
  WithTitleBlock
} from 'shared';

import {
  CHOOSE_NUMBERS_COUNT,
  getLuckyGameAreaFieldValidation
} from '../../../shared/constants/lucky-game';

interface Props {
  totalSelectableNumbers: number;
  type: LUCKY_GAME_TYPES;
  combinations: LuckyCombination[];
}

export const LuckyGameArea = ({ combinations, totalSelectableNumbers, type }: Props) => {
  const isGame4 = type === LUCKY_GAME_TYPES.GAME4;

  const { register, reset, watch, setValue, handleSubmit, onSetCombination, formOptions } =
    useSetLuckyGameCombinations({ combinations, totalSelectableNumbers, type });

  const [isOpenNotificationModal, handleOpenNotificationModal, handleCloseNotificationModal] =
    useBoolean();

  const { startGenerating, isGenerating, numbers } = useRandomNumbersWithIterations({
    min: 1,
    max: isGame4 ? 8 : totalSelectableNumbers,
    maxNumbers: Number(CHOOSE_NUMBERS_COUNT[type]?.label),
    shouldBeUnique: true,
    onIteration: (numbers) => {
      reset();

      if (isGame4) {
        numbers.map((number, index) => setValue(`row_${index + 1}`, [String(number)]));
        return;
      }

      setValue(`row_1`, numbers.map(String));
    }
  });

  const { startGenerating: startGeneratingBonusNumber, isGenerating: isGeneratingBonusNumber } =
    useRandomNumbersWithIterations({
      min: 1,
      max: 8,
      maxNumbers: 1,
      onIteration: (res) => {
        setValue(`row_bonusNumber`, res.map(String));
      }
    });

  const actions = [
    {
      id: 'clear',
      icon: ClearSvg,
      action: () => reset()
    },
    {
      id: 'generate-random',
      icon: RandomSvg,
      action: () => {
        startGenerating();
      }
    },
    {
      id: 'set-combination',
      icon: PlusSvg,
      action: handleSubmit(onSetCombination, handleOpenNotificationModal)
    }
  ];

  useEffect(() => {
    if (
      type === LUCKY_GAME_TYPES.GAME2 &&
      numbers?.length === Number(CHOOSE_NUMBERS_COUNT[type]?.label)
    ) {
      startGeneratingBonusNumber();
    }
  }, [numbers?.length, type]);

  if (!formOptions?.length) return null;

  return (
    <>
      <Modal
        header="Error"
        description={`${CHOOSE_NUMBERS_COUNT[type]?.label} numbers must be chosen`}
        isOpened={isOpenNotificationModal}
        handleClose={handleCloseNotificationModal}
        height={25}
      />
      <WithTitleBlock title="My Game">
        <div className="flex gap-3.5">
          {actions?.map(({ id, icon: Icon, action }) => (
            <Button
              key={id}
              onClick={action}
              className="basis-1/3 border rounded-xl bg-blue-50 text-black hover:bg-blue-100 px-3 py-2"
            >
              <Icon />
            </Button>
          ))}
        </div>
        <WithTitleBlock
          title={`Choose ${CHOOSE_NUMBERS_COUNT[type]?.label} numbers:`}
          blockClassName="mt-4"
          titleClassName="text-md"
        >
          {formOptions?.length ? (
            <form onSubmit={handleSubmit(onSetCombination)} className="space-y-3.5 h-full">
              {formOptions?.map(({ id, label, options }) => {
                const name = id as `row_${string}`;

                const groupValues = watch(name);

                const isBonusField = name.includes('bonusNumber');

                const chosenNumber = CHOOSE_NUMBERS_COUNT[type];

                const disabled =
                  groupValues?.length >= (isBonusField ? 1 : chosenNumber?.formValue);

                return (
                  <SwitchableInputsGroup
                    key={id}
                    className="grid grid-cols-8 gap-3.5"
                    options={options}
                    label={label}
                    renderContent={(option) => (
                      <SwitchableInput
                        key={option.value}
                        disabled={
                          (disabled && !groupValues?.includes(String(option?.value))) ||
                          isGeneratingBonusNumber ||
                          isGenerating
                        }
                        {...option}
                        {...register(name, getLuckyGameAreaFieldValidation(type, isBonusField))}
                      />
                    )}
                  />
                );
              })}
            </form>
          ) : null}
        </WithTitleBlock>
      </WithTitleBlock>
    </>
  );
};
export default LuckyGameArea;
