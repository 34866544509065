import { twMerge } from 'tailwind-merge';

import { ReactComponent as CoinIcon } from 'assets/svg/coins.svg';

interface Props {
  balance: number;
  className?: string;
}

function formatNumber(number: number) {
  return number.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'short'
  });
}

export const UserBalance = ({ balance, className }: Props) => {
  const formattedBalance = formatNumber(balance);

  return (
    <div className={twMerge('flex flex-col items-center', className)}>
      <div className="flex font-bold">
        <span className="text-[40px] leading-7">{formattedBalance}</span>
        <CoinIcon className="w-8 ml-1.5 fill-white" />
      </div>
    </div>
  );
};
