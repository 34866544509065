import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { asyncGetUpgrades } from './action';
import { BoostersSliceState } from './slice';
import { Upgrade, UpgradeTypes } from '../../types/boosters.interfaces';

export function getUpgradesReducer(builder: ActionReducerMapBuilder<BoostersSliceState>) {
  builder.addCase(asyncGetUpgrades.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    asyncGetUpgrades.fulfilled,
    (state, { payload }: PayloadAction<Record<UpgradeTypes, Upgrade>>) => {
      state.upgrades = payload;
      state.isLoading = false;
    }
  );

  builder.addCase(asyncGetUpgrades.rejected, (state) => {
    state.isLoading = false;
    state.upgrades = null;
  });
}
