import { MouseEvent, useRef } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as TimerIcon } from 'assets/svg/timer.svg';
import { asyncClaimTokens, Button, useAppDispatch, useAvailableClaim } from 'shared';
import { ButtonVariants } from 'shared/constants/button';
import { useTimer } from 'shared/lib/hooks/useTimer';
import { HapticFeedbackStyles } from 'shared/types/telegram.interfaces';

interface Props {
  userId: number;
  lastClaim?: string;
  className?: string;
}

export const ButtonClaim = ({ userId, lastClaim, className }: Props) => {
  const [timeLeftClaim, isCalcClaimTime] = useTimer(lastClaim, 4, 0, 0);
  const [doubleClaimTime, isCalcDoubleClaimTime] = useTimer(lastClaim, 4, 5, 0);

  const buttonContainerRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useAppDispatch();

  const [availableClaim, boostClaim] = useAvailableClaim();

  const isFullClaim = availableClaim >= 1250;

  const sendClaimedTokens = (event: MouseEvent<HTMLButtonElement>) => {
    const CLAIM_TYPE = boostClaim === 2 ? 'double' : 'quadro';

    if (!userId) return;

    dispatch(
      asyncClaimTokens({
        income: availableClaim,
        ...(boostClaim > 1 ? { type: CLAIM_TYPE } : undefined)
      })
    )
      .unwrap()
      .then(() => {
        const eventTarget = event.target as HTMLButtonElement;

        const rect = eventTarget.getBoundingClientRect();
        const animationNode = document.createElement('div');
        animationNode.classList.add('move_up');
        animationNode.textContent = `+${availableClaim}`;
        animationNode.style.left = `${event.clientX - rect?.left}px`;
        animationNode.style.top = `${event.clientY - rect?.top - 10}px`;

        buttonContainerRef?.current?.appendChild(animationNode);

        const removeAnimationNodeTimeout = setTimeout(() => {
          animationNode.remove();
        }, 2000);

        clearTimeout(removeAnimationNodeTimeout);
      });
  };

  return (
    <div
      className={twMerge('relative flex flex-col w-full justify-center items-center', className)}
    >
      <div className="w-full relative" ref={buttonContainerRef}>
        <Button
          variant={ButtonVariants.SECONDARY}
          disabled={!availableClaim}
          onClick={sendClaimedTokens}
          hapticStyle={HapticFeedbackStyles.RIGID}
        >
          <div
            className={cn(
              'flex flex-col items-center text-xs bg-[#FCB702] py-3 justify-center duration-200 overflow-hidden w-0 h-full',
              {
                '!w-20': isCalcClaimTime || isCalcDoubleClaimTime
              }
            )}
          >
            <TimerIcon className="w-4 h-4 mb-1" />
            {isCalcClaimTime ? `${timeLeftClaim}` : `${doubleClaimTime}`}
          </div>
          <span className="w-full flex items-center justify-center py-3 px-2 text-[#AB7F00]">
            <strong className="text-black mr-2">{availableClaim} </strong>
            {isFullClaim ? 'FULL CLAIM!' : 'CLAIM'}
          </span>
        </Button>
      </div>
    </div>
  );
};
