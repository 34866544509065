import { UPGRADE_LEVEL_KEYS } from 'shared/constants/boosters';
import { selectUpgrades } from 'shared/model/boosters/selectors';
import { selectUser } from 'shared/model/user/selectors';
import { UpgradeTypes } from 'shared/types/boosters.interfaces';

import { useAppSelector } from './useAppSelector';

export const useGetCurrentUpgrade = (searchUpgrade: UpgradeTypes) => {
  const user = useAppSelector(selectUser);
  const upgrades = useAppSelector(selectUpgrades);

  const currentUpgrade = upgrades?.[searchUpgrade]?.levels?.find(
    (item) => item.level === user?.[UPGRADE_LEVEL_KEYS[searchUpgrade]]
  );

  return currentUpgrade;
};
