export const generateArrayOfOptionsByNumber = (number: number) => {
  return Array.from(Array(number).keys()).map((key) => ({
    label: String(key + 1),
    value: key + 1
  }));
};

export const generateRandomNumbers = ({
  maxNumbers,
  max,
  min,
  unique
}: {
  maxNumbers: number;
  max: number;
  min: number;
  unique?: boolean;
}) => {
  if (unique) {
    const availableNumbers: Set<number> = new Set();
    while (availableNumbers.size < (maxNumbers || max - min + 1)) {
      const randomNumber = generateRandomNumber({ max, min });
      availableNumbers.add(randomNumber);
    }
    return Array.from(availableNumbers);
  }

  const numbers: number[] = [];
  for (let i = 0; i < maxNumbers; i++) {
    const randomNumber = generateRandomNumber({ max, min });
    numbers.push(randomNumber);
  }
  return numbers;
};

export const generateRandomNumber = ({ max, min }: { max: number; min: number }) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
