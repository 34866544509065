import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { Typography } from './Typography';
import { TypographyVariants } from '../constants/typography';

interface Props {
  title: string;
  description?: string;
  titleClassName?: string;
  blockClassName?: string;
  childrenClassName?: string;
  children: ReactNode;
}

export const WithTitleBlock = ({
  title,
  titleClassName,
  blockClassName,
  childrenClassName,
  description,
  children
}: Props) => {
  return (
    <div className={blockClassName}>
      <h5 className={twMerge('text-lg font-medium text-white', titleClassName)}>{title}</h5>
      {description && (
        <Typography className="text-[#858994]" variant={TypographyVariants.NORMAL}>
          {description}
        </Typography>
      )}

      <div className={twMerge('flex flex-col gap-1.5 mt-4 h-full', childrenClassName)}>
        {children}
      </div>
    </div>
  );
};
