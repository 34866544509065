import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const FullScreenLayout = ({ children }: Props) => {
  return (
    <div className="h-dvh flex flex-col justify-center w-screen text-white font-RedHatDisplay overflow-hidden bg-black text-center">
      {children}
    </div>
  );
};
