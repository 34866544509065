import { LeaderboardCard } from 'entities/leaderboard';
import { Button, getTelegramApi, selectUser, Typography, useAppSelector } from 'shared';
import { LeaderboardType } from 'shared/types/query.interfaces';

export const TopFriends = () => {
  const user = useAppSelector(selectUser);
  const tg = getTelegramApi();

  const onInvite = () => {
    tg.openTelegramLink(`https://t.me/share/url?url=${user?.referralLink}`);
  };

  if (!user?.referral_users) return null;

  return (
    <>
      <h2 className="text-2xl text-center font-bold">YOUR FRIENDS</h2>
      {user.referral_users.length ? (
        <div className="flex flex-col gap-3.5 h-full mt-4">
          {user.referral_users.map(({ reward, user }, index) => (
            <LeaderboardCard
              key={reward + index}
              position={index + 1}
              data={user}
              type={LeaderboardType.POINTS}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-col w-full h-full justify-center items-center gap-3">
          <Typography tag="h2" className="text-xl text-center opacity-70">
            You don`t have any invited friends yet.
          </Typography>
          <Button onClick={onInvite} className="w-full text-base py-2 shadow-inner shadow-zinc-800">
            Shared link
          </Button>
        </div>
      )}
    </>
  );
};
