import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app';

const selectState = (x: RootState) => x.gamesReducer;

export const selectGames = createSelector(selectState, ({ games }) => games);

export const selectIsGamesLoading = createSelector(selectState, ({ isLoading }) => isLoading);

export const selectGameReward = createSelector(selectState, ({ gameReward }) => gameReward);
