import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  children: ReactNode;
  className?: string;
}

export const LuckyGameCardCombinationNumber = ({ children, className }: Props) => {
  return (
    <div
      className={twMerge(
        'w-7 h-7 flex justify-center items-center rounded-full bg-gray-600 leading-normal',
        className
      )}
    >
      {children}
    </div>
  );
};
