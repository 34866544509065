import { useCallback, useState } from 'react';

export const useBoolean = <T extends object>(
  initialValue?: boolean
): [boolean, (values?: T) => void, VoidFunction, T | null] => {
  const [value, setValue] = useState(initialValue || false);
  const [additionalProps, setAdditionalProps] = useState<T | null>(null);

  const handleSetTrue = useCallback((props?: T) => {
    if (props) setAdditionalProps(props);

    setValue(true);
  }, []);

  const handleSetFalse = useCallback(() => {
    setValue(false);
    setAdditionalProps(null);
  }, []);

  return [value, handleSetTrue, handleSetFalse, additionalProps];
};
