import { useCallback, useContext, useEffect, useRef } from 'react';

import { GameStatusModal } from 'features/game-status-modal';
import { useAppDispatch } from 'shared';
import { GameStatus } from 'shared/constants/games';
import { setGameReward } from 'shared/model/games/action';

import MobileSwiper, { SwipeInput } from './MobileSwiper';
import { Tile } from './Tile';
import { BLOCK_SIZE, ITEM_SIZE } from '../constants/constants';
import { Tile as TileModel } from '../constants/tile.interface';
import { Game2048Context } from '../context/game-2048-context';

export const Board2048 = () => {
  const dispatch = useAppDispatch();

  const { getTiles, moveTiles, status, startGame, score } = useContext(Game2048Context);

  const initialized = useRef(false);

  const renderGrid = () => {
    const cells: JSX.Element[] = [];
    const totalCellsCount = 16;

    for (let index = 0; index < totalCellsCount; index += 1) {
      cells.push(
        <div
          style={{ width: ITEM_SIZE, height: ITEM_SIZE }}
          className="cell rounded-md bg-[#cebda6] m-1"
          key={index}
        />
      );
    }

    return cells;
  };

  const renderTiles = () => {
    return getTiles().map((tile: TileModel) => <Tile key={`${tile?.id}`} {...tile} />);
  };

  const handleSwipe = useCallback(
    ({ deltaX, deltaY }: SwipeInput) => {
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        if (deltaX > 0) {
          moveTiles('move_right');
        } else {
          moveTiles('move_left');
        }
      } else {
        if (deltaY > 0) {
          moveTiles('move_down');
        } else {
          moveTiles('move_up');
        }
      }
    },
    [moveTiles]
  );

  useEffect(() => {
    if (initialized.current === false) {
      startGame();
      initialized.current = true;
    }
  }, [startGame]);

  useEffect(() => {
    if (status === 'lost') {
      dispatch(setGameReward(score));
    }
  }, [status]);

  return (
    <>
      <div className="flex justify-between my-5">
        <h2 className="flex-1 text-6xl text-white">2048</h2>
        <div className="flex flex-col items-center flex-1 bg-[#b59d87] rounded-md text-[#f9f6f2] text-xl">
          Score <p>{score}</p>
        </div>
      </div>
      <div
        style={{ width: BLOCK_SIZE, height: BLOCK_SIZE }}
        className="bg-[#b59d87] rounded-md p-2 relative"
      >
        {status === 'won' && (
          <GameStatusModal heading="You won!" status={GameStatus.WON} handleClick={startGame} />
        )}
        {status === 'lost' && (
          <GameStatusModal heading="You lost!" status={GameStatus.LOST} handleClick={startGame} />
        )}

        <MobileSwiper onSwipe={handleSwipe}>
          <div className="relative">{renderTiles()}</div>
          <div className="w-full h-full flex flex-wrap">{renderGrid()}</div>
        </MobileSwiper>
      </div>
    </>
  );
};
