import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { GamesPayload } from 'shared/types/games.interfaces';

import { asyncGetGames, setGameReward } from './action';
import { GamesSliceState } from './slice';

export function getGamesReducer(builder: ActionReducerMapBuilder<GamesSliceState>) {
  builder.addCase(asyncGetGames.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    asyncGetGames.fulfilled,
    (state, { payload }: PayloadAction<{ games: GamesPayload[] }>) => {
      state.games = payload.games;
      state.isLoading = false;
    }
  );

  builder.addCase(asyncGetGames.rejected, (state) => {
    state.games = null;
    state.isLoading = false;
  });
}

export function setGameRewardReducer(builder: ActionReducerMapBuilder<GamesSliceState>) {
  builder.addCase(setGameReward, (state, action: PayloadAction<number>) => {
    state.gameReward = action.payload;
  });
}
