import { useRef, useEffect, useState } from 'react';

import BirdPNG from 'assets/png/flappy-bird/bird.gif';
import PipeBottomPng from 'assets/png/flappy-bird/pipeBottom.png';
import PipeTopPng from 'assets/png/flappy-bird/pipeTop.png';
import { GameStatusModal } from 'features/game-status-modal';
import { useAppDispatch } from 'shared';
import { GameStatus } from 'shared/constants/games';
import { setGameReward } from 'shared/model/games/action';

import {
  BIRD_SIZE,
  GAME_HEIGHT,
  GAME_WIDTH,
  GRAVITY,
  JUMP,
  OBSTACLE_GAP,
  OBSTACLE_WIDTH,
  TUBES_GAP
} from '../constants/constants';

export const FlappyBird = () => {
  const dispatch = useAppDispatch();

  const [isStartGame, setIsStartGame] = useState<boolean>(false);
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [status, setStatus] = useState<GameStatus>(GameStatus.DEFAULT);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [birdPosition, setBirdPosition] = useState<number>(200);
  const [birdVelocity, setBirdVelocity] = useState<number>(0);
  const [obstacles, setObstacles] = useState<
    Array<{ x: number; height: number; isClaim: boolean }>
  >([]);
  const [score, setScore] = useState<number>(0);

  useEffect(() => {
    let animationFrameId: number;

    const gameLoop = () => {
      if (isGameRunning) {
        setBirdVelocity((velocity) => velocity + GRAVITY);
        setBirdPosition((position) => Math.min(position + birdVelocity, GAME_HEIGHT - BIRD_SIZE));

        setObstacles((obs) =>
          obs.map((obs) => ({ ...obs, x: obs.x - 3 })).filter((obs) => obs.x + OBSTACLE_WIDTH > 0)
        );

        generateObstacles();

        if (birdPosition >= GAME_HEIGHT - BIRD_SIZE) {
          endGame();
        }

        obstacles.forEach((obstacle) => {
          const inObstacleRange = obstacle.x < BIRD_SIZE + 25 && obstacle.x + OBSTACLE_WIDTH > 50;
          const hitObstacle =
            birdPosition < obstacle.height - 20 || birdPosition > obstacle.height + TUBES_GAP - 20;
          if (inObstacleRange && hitObstacle) {
            endGame();
          }
        });

        if (obstacles.length > 0 && obstacles[0].x < 1 && !obstacles[0].isClaim) {
          obstacles.splice(0, 1, { ...obstacles[0], isClaim: true });

          setScore((prev) => prev + 1 * 10);
        }
      }

      renderCanvas();
      animationFrameId = requestAnimationFrame(gameLoop);
    };

    const renderCanvas = () => {
      const ctx = canvasRef.current?.getContext('2d');
      if (!ctx) return;

      ctx.clearRect(0, 0, GAME_WIDTH, GAME_HEIGHT);

      // Отрисовка background
      //   const bgImg = new Image();
      //   bgImg.src = BackgroundPng;
      //   ctx.drawImage(bgImg, 0, 0, 364, 364);

      // Render Bird
      const bird = new Image();
      bird.src = BirdPNG;
      ctx.drawImage(bird, 0, birdPosition, 64, 64);

      // Render Obstacles
      const pipeTop = new Image();
      pipeTop.src = PipeTopPng;
      const pipeBottom = new Image();
      pipeBottom.src = PipeBottomPng;

      obstacles.forEach((obstacle) => {
        ctx.drawImage(pipeTop, obstacle.x, 0, OBSTACLE_WIDTH, obstacle.height);
        ctx.drawImage(
          pipeBottom,
          obstacle.x,
          obstacle.height + TUBES_GAP,
          OBSTACLE_WIDTH,
          GAME_HEIGHT - obstacle.height - TUBES_GAP
        );
      });
    };

    animationFrameId = requestAnimationFrame(gameLoop);

    return () => cancelAnimationFrame(animationFrameId);
  }, [birdPosition, birdVelocity, obstacles, isGameRunning]);

  const initData = () => {
    setScore(0);
    setBirdPosition(200);
    setBirdVelocity(0);
    setObstacles([{ x: 300, height: 200, isClaim: false }]);
    setStatus(GameStatus.DEFAULT);
  };

  const generateObstacles = () => {
    if (
      obstacles.length === 0 ||
      obstacles[obstacles.length - 1].x < GAME_WIDTH - OBSTACLE_GAP - score
    ) {
      setObstacles((obs) => [
        ...obs,
        { x: GAME_WIDTH, height: Math.random() * (GAME_HEIGHT - 210) + 10, isClaim: false }
      ]);
    }
  };

  const endGame = () => {
    dispatch(setGameReward(score));

    setIsGameRunning(false);
    setStatus(GameStatus.LOST);
  };

  const handleTouch = () => {
    setBirdVelocity(JUMP);
  };

  const startGame = () => {
    setIsGameRunning(true);

    if (!isStartGame && status === GameStatus.DEFAULT) {
      setIsStartGame(true);

      return;
    }

    initData();
  };

  return (
    <div className="relative">
      <canvas
        onTouchStart={handleTouch}
        ref={canvasRef}
        width={GAME_WIDTH}
        height={GAME_HEIGHT}
        style={{ border: '2px solid black' }}
        className="bg-white"
      />
      <div className="absolute top-2 right-2 font-bold bg-[#b59d87] px-4 py-0.5 rounded-full">
        {score}
      </div>

      {!isStartGame && <GameStatusModal handleClick={startGame} />}
      {status === GameStatus.LOST && (
        <GameStatusModal status={GameStatus.LOST} handleClick={startGame} />
      )}
    </div>
  );
};
