import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import {
  buyLuckyGameTickets,
  checkGameResult,
  getLuckyGame,
  getLuckyGames,
  getLuckyGamesResults,
  setLuckyGameCombinations
} from './action';
import { LuckyGamesSliceState } from './slice';
import {
  GameResultResponce,
  GamesResultsResponse,
  LuckyCombination,
  LuckyGame,
  PartialLuckyGame
} from '../../types/lucky-games.interfaces';

export function getLuckyGamesReducer(builder: ActionReducerMapBuilder<LuckyGamesSliceState>) {
  builder.addCase(getLuckyGames.pending, (state) => {
    state.isGamesLoading = true;
  });

  builder.addCase(
    getLuckyGames.fulfilled,
    (state, { payload }: PayloadAction<PartialLuckyGame[]>) => {
      state.games = payload;
      state.isGamesLoading = false;
    }
  );

  builder.addCase(getLuckyGames.rejected, (state) => {
    state.isGamesLoading = false;
    state.games = [];
  });
}

export function getLuckyGameReducer(builder: ActionReducerMapBuilder<LuckyGamesSliceState>) {
  builder.addCase(getLuckyGame.pending, (state) => {
    state.isGameLoading = true;
    state.game = null;
  });

  builder.addCase(getLuckyGame.fulfilled, (state, { payload }: PayloadAction<LuckyGame>) => {
    state.game = payload;
    state.isGameLoading = false;
  });

  builder.addCase(getLuckyGame.rejected, (state) => {
    state.isGameLoading = false;
  });
}

export function buyLuckyGameTicketsReducer(builder: ActionReducerMapBuilder<LuckyGamesSliceState>) {
  builder.addCase(
    buyLuckyGameTickets.fulfilled,
    (state, { payload }: PayloadAction<LuckyCombination[]>) => {
      if (state.game) {
        state.game.combinations = payload;
      }
    }
  );
}

export function setLuckyGameCombinationsReducer(
  builder: ActionReducerMapBuilder<LuckyGamesSliceState>
) {
  builder.addCase(
    setLuckyGameCombinations,
    (state, { payload }: PayloadAction<LuckyCombination[]>) => {
      if (state.game) {
        state.game.combinations = payload;
      }
    }
  );
}

//Lucky-game result reducer

export function getLuckyGameResultsReducer(builder: ActionReducerMapBuilder<LuckyGamesSliceState>) {
  builder.addCase(getLuckyGamesResults.pending, (state) => {
    state.isResultsLoading = true;
    state.results = null;
  });

  builder.addCase(
    getLuckyGamesResults.fulfilled,
    (state, { payload }: PayloadAction<GamesResultsResponse>) => {
      state.results = payload;
      state.isResultsLoading = false;
    }
  );

  builder.addCase(getLuckyGamesResults.rejected, (state) => {
    state.isResultsLoading = false;
  });
}

export function checkGameResultReducer(builder: ActionReducerMapBuilder<LuckyGamesSliceState>) {
  builder.addCase(checkGameResult.pending, (state) => {
    state.isResultLoading = true;
    state.result = null;
  });

  builder.addCase(
    checkGameResult.fulfilled,
    (state, { payload }: PayloadAction<GameResultResponce>) => {
      state.result = payload;
      state.isResultLoading = false;
    }
  );

  builder.addCase(checkGameResult.rejected, (state) => {
    state.isResultLoading = false;
  });
}
