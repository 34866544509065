import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { GameCenter } from 'features/game-center';
import { asyncGetGames } from 'shared/model/games/action';
import { selectIsGamesLoading } from 'shared/model/games/selectors';
import { SkeletonGamesList } from 'widgets/skeleton/components/games-list/SkeletonGamesList';

import { useAppDispatch, useAppSelector } from '../shared';

export const Activities = () => {
  const dispatch = useAppDispatch();

  const isGamesLoading = useAppSelector(selectIsGamesLoading);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(asyncGetGames());
  }, [dispatch]);

  useEffect(() => {
    if (searchParams.has('game')) {
      searchParams.delete('game');
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <div className="h-full">
      <h2 className="text-2xl font-bold text-center">ACTIVITIES</h2>

      {isGamesLoading ? <SkeletonGamesList /> : <GameCenter />}
    </div>
  );
};
