import LuckyGamesSkeleton from './LuckyGamesSkeleton';

const LuckyGamesSkeletonContainer = () => {
  return (
    <div className="space-y-1.5">
      {Array.from(Array(3).keys()).map((key) => (
        <LuckyGamesSkeleton key={key} />
      ))}
    </div>
  );
};

export default LuckyGamesSkeletonContainer;
