import { LeaderboardCard } from 'entities/leaderboard';
import { selectUser, Typography, useAppSelector } from 'shared';
import { LeaderboardType } from 'shared/types/query.interfaces';

export const TopFriendRef = () => {
  const user = useAppSelector(selectUser);

  if (!user?.ref_from_refs) return null;

  return (
    <>
      <h2 className="text-2xl text-center font-bold">FRIENDS OF YOUR FRIENDS</h2>
      {user.ref_from_refs.length ? (
        <div className="flex flex-col gap-3.5 h-full mt-4">
          {user.ref_from_refs.map(({ reward, user }, index) => (
            <LeaderboardCard
              key={reward + index}
              position={index + 1}
              data={user}
              type={LeaderboardType.POINTS}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-col w-full h-full justify-center items-center gap-3">
          <Typography tag="h2" className="text-xl text-center opacity-70">
            Your friends don`t have any referrals yet.
          </Typography>
        </div>
      )}
    </>
  );
};
