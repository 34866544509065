import React, { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  children: ReactNode;
  className?: string;
}

export const Block = ({ children, className }: Props) => {
  return (
    <div className={twMerge('relative p-4 bg-[#282B30] rounded-xl', className)}>{children}</div>
  );
};
