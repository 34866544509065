import { useEffect } from 'react';

import { HomeBlock } from 'entities/home';
import { asyncGetUser, getTelegramApi, useAppDispatch } from 'shared';

localStorage.setItem('TOKEN_NAME', '$COI');

export const Home = () => {
  const dispatch = useAppDispatch();

  const tg = getTelegramApi();

  useEffect(() => {
    tg.BackButton.isVisible = false;
    const intervalId = setInterval(
      () =>
        dispatch(
          asyncGetUser({ id: process.env.REACT_APP_MOCK_USER_ID, ...tg?.initDataUnsafe?.user })
        ),
      30000
    );

    return () => clearInterval(intervalId);
  }, [dispatch, tg.BackButton, tg?.initDataUnsafe?.user]);

  return <HomeBlock />;
};
