import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from '../config/http-client.configs';
import { BuyLuckyGameTicketsModalProps } from '../lib/hooks/games/useBuyLuckyGameTickets';
import {
  GameResultResponce,
  GamesResultsResponse,
  LotteryClearCombinationPayload,
  LotterySetCombinationPayload,
  LuckyCombination,
  LuckyGame,
  PartialLuckyGame
} from '../types/lucky-games.interfaces';

export class LotteryApi {
  constructor(private url: string) {}

  getLotteries(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ lotteries: PartialLuckyGame[] }>(`${this.url}`, config);
  }

  getLotteryById(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ lottery: LuckyGame }>(`${this.url}/${id}`, config);
  }

  buyLotteryAccessById(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.patch(`${this.url}/${id}/draw-participate`, config);
  }

  buyLotteryTickets(
    { id, ticketsCount }: Omit<BuyLuckyGameTicketsModalProps, 'price'>,
    config?: AxiosRequestConfig
  ) {
    return httpApiClient.patch<{ combinations: LuckyCombination[] }>(
      `${this.url}/${id}/buy-tickets`,
      { ticketsCount },
      config
    );
  }

  setLotteryCombinationById(
    { id, ...payload }: LotterySetCombinationPayload,
    config?: AxiosRequestConfig
  ) {
    return httpApiClient.patch<{ combinations: LuckyCombination[] }>(
      `${this.url}/${id}/set-combination`,
      payload,
      config
    );
  }

  clearLotteryCombinationById(
    { id, ...payload }: LotteryClearCombinationPayload,
    config?: AxiosRequestConfig
  ) {
    return httpApiClient.patch<{ combinations: LuckyCombination[] }>(
      `${this.url}/${id}/clear-combination`,
      payload,
      config
    );
  }

  getLotteriesResults(config?: AxiosRequestConfig) {
    return httpApiClient.get<GamesResultsResponse>(`${this.url}/results`, config);
  }

  checkLottery(lotteryId: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<GameResultResponce>(`${this.url}/${lotteryId}/check-result`, config);
  }

  claimLottery(lotteryId: string, config?: AxiosRequestConfig) {
    return httpApiClient.patch(`${this.url}/${lotteryId}/claim-reward`, config);
  }
}

export const lotteryApi = new LotteryApi('/lottery');
