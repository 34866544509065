import { useEffect, useState } from 'react';

import cn from 'classnames';

import { ReactComponent as CheckIcon } from 'assets/svg/checked.svg';

export const AnimatedCheckmark = () => {
  const [animated, setAnimated] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => setAnimated(true), 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-10">
      <div
        className={cn('w-0 overflow-hidden duration-300 ease-in', {
          'w-10': animated
        })}
      >
        <CheckIcon className="w-10 h-10 fill-green-500" />
      </div>
    </div>
  );
};
