import { ReactComponent as LotteryRewardsIcon } from 'assets/svg/activity/lottery.svg';
import { ReactComponent as AutobotIcon } from 'assets/svg/boosts/autobot.svg';
import { ReactComponent as BoostIcon } from 'assets/svg/boosts/boost.svg';
import { ReactComponent as DailyIcon } from 'assets/svg/boosts/daily.svg';
import { ReactComponent as DoubleIcon } from 'assets/svg/boosts/double.svg';
import { ReactComponent as FriendsRewardsIcon } from 'assets/svg/boosts/friends_rewards.svg';
import { ReactComponent as GamesRewardsIcon } from 'assets/svg/boosts/games_rewards.svg';
import { ReactComponent as RewardIcon } from 'assets/svg/boosts/reward.svg';
import { ReactComponent as UpgradesIcon } from 'assets/svg/boosts/upgrades.svg';

import { UpgradeTypes } from '../types/boosters.interfaces';
import { SVGComponent } from '../types/svg.interfaces';
import { User } from '../types/users.interfaces';

export const DOUBLE_BOOST_DETAILS = {
  icon: DoubleIcon,
  title: 'Claim next 3 times X2',
  maxAvailable: 3,
  description: `Available`,
  buttonText: 'Use',
  usedText: 'Used',
  type: 'double'
};

export const BOOST_CLAIM_DETAILS = {
  icon: BoostIcon,
  title: 'Boost claim',
  maxAvailable: 2,
  description: `Available`,
  buttonText: 'Use',
  usedText: 'Used',
  unavailableText: 'Upgrade',
  type: 'boost'
};

export const getUpgrades = ({ availableBot }: { availableBot?: boolean }) => [
  {
    icon: AutobotIcon,
    title: 'Auto bot',
    description: 'enable bot for auto claim',
    button_text: 'Unlock',
    aviable: availableBot,
    type: 'auto-bot'
  },
  {
    icon: UpgradesIcon,
    title: 'Boost all upgrades',
    description: 'unlock all upgrades',
    button_text: 'TBA',
    type: 'boost-all-upgrades'
  }
];

export const UPGRADE_LEVEL_KEYS: {
  [key in UpgradeTypes]: keyof Pick<
    User,
    | 'daily_claim_lvl'
    | 'reward_claim_lvl'
    | 'friends_reward_lvl'
    | 'games_reward_lvl'
    | 'lottery_reward_lvl'
  >;
} = {
  [UpgradeTypes.DAILY_CLAIM]: 'daily_claim_lvl',
  [UpgradeTypes.REWARD_CLAIM]: 'reward_claim_lvl',
  [UpgradeTypes.FRIENDS_REWARDS]: 'friends_reward_lvl',
  [UpgradeTypes.GAMES_REWARDS]: 'games_reward_lvl',
  [UpgradeTypes.LOTTERY_REWARD]: 'lottery_reward_lvl'
};

export const UPGRADES_ICONS: { [key in UpgradeTypes]: SVGComponent } = {
  [UpgradeTypes.DAILY_CLAIM]: DailyIcon,
  [UpgradeTypes.REWARD_CLAIM]: RewardIcon,
  [UpgradeTypes.FRIENDS_REWARDS]: FriendsRewardsIcon,
  [UpgradeTypes.GAMES_REWARDS]: GamesRewardsIcon,
  [UpgradeTypes.LOTTERY_REWARD]: LotteryRewardsIcon
};
