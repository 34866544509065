import { useEffect } from 'react';

import { asyncGetBonuses, selectBonuses, useAppDispatch, useAppSelector } from 'shared';

import { BonusesList } from './BonusesList';

export const BonusesBlock = () => {
  const dispatch = useAppDispatch();

  const bonuses = useAppSelector(selectBonuses);

  useEffect(() => {
    dispatch(asyncGetBonuses());
  }, [dispatch]);

  if (!bonuses) return <p className="text-center">Coming soon</p>;

  return (
    <div className="flex flex-col gap-3">
      {bonuses?.map(({ title, bonuses }) => (
        <BonusesList key={title} title={title} data={bonuses} isTitleUnstyled />
      ))}
    </div>
  );
};
