import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { Tab } from '../types/tabs.interfaces';

interface Props<T = void> {
  tabs: Tab[];
  componentProps?: T;
  className?: string;
  contentClassName?: string;
  onTabClick?: (index: number, id: string) => void;
}

export const Tabs = <T,>({
  tabs,
  componentProps,
  className,
  contentClassName,
  onTabClick
}: Props<T>) => {
  const tabsRef = useRef<(HTMLElement | null)[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const TabContentComponent = tabs[activeTabIndex]?.component || null;

  const handleChangeTab = (index: number) => {
    setActiveTabIndex(index);
    onTabClick?.(index, tabs[index]?.id);
  };

  useEffect(() => {
    if (activeTabIndex === null) {
      return;
    }

    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex] as HTMLElement;
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };

    setTabPosition();
  }, [activeTabIndex]);

  if (!tabs?.length) return null;

  return (
    <div>
      <div
        className={twMerge(
          'w-full flex flew-row relative mx-auto mb-8 px-2 rounded-xl bg-[#282B30] backdrop-blur-sm font-interTight',
          className
        )}
      >
        <span
          className="flex absolute bottom-0 top-0 -z-10 py-2 transition-all duration-300"
          style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
        >
          <span className="h-full w-full rounded-[10px] bg-black" />
        </span>

        {tabs?.map(({ title, description }, index) => {
          const isActive = activeTabIndex === index;

          return (
            <button
              key={index}
              style={{ width: `${100 / tabs?.length}%` }}
              ref={(el) => (tabsRef.current[index] = el)}
              className={twMerge(
                cn(
                  'my-1 mr-4 last:mr-0 py-2 px-2 cursor-pointer select-none rounded-2xl text-center font-medium text-white text-base',
                  {
                    'text-white': isActive
                  }
                )
              )}
              onClick={() => handleChangeTab(index)}
            >
              {title}{' '}
              {description && <span className="text-[#413B7D] font-normal">{description}</span>}
            </button>
          );
        })}
      </div>
      <div className={twMerge('h-full', contentClassName)}>
        {TabContentComponent && <TabContentComponent {...componentProps} />}
      </div>
    </div>
  );
};
