import { Dispatch } from 'react';

import { ReactComponent as ArrowTest } from 'assets/svg/arrow-game.svg';
import { Button } from 'shared';

import { Direction } from '../constants/constants';

interface Props {
  currentDirection: Direction;
  setDirection: Dispatch<React.SetStateAction<Direction>>;
}

const BUTTON_CLASSNAME = 'bg-white w-16 h-16 p-2 rounded-none';

export const Controller = ({ currentDirection, setDirection }: Props) => {
  const handleClick = (dir: Direction) => {
    setDir(dir);
  };

  const setDir = (dir: Direction) => {
    switch (dir) {
      case Direction.RIGHT:
        if (currentDirection !== Direction.LEFT && currentDirection !== Direction.RIGHT) {
          setDirection(Direction.RIGHT);
        }
        break;
      case Direction.LEFT:
        if (currentDirection !== Direction.RIGHT && currentDirection !== Direction.LEFT) {
          setDirection(Direction.LEFT);
        }
        break;
      case Direction.DOWN:
        if (currentDirection !== Direction.UP && currentDirection !== Direction.DOWN) {
          setDirection(Direction.DOWN);
        }
        break;
      case Direction.UP:
        if (currentDirection !== Direction.DOWN && currentDirection !== Direction.UP) {
          setDirection(Direction.UP);
        }
        break;
    }
  };

  return (
    <div className="flex justify-center">
      <div className="grid mt-12 grid-cols-3 grid-rows-3 w-fit h-fit">
        <div />
        <Button onClick={() => handleClick(Direction.UP)} className={BUTTON_CLASSNAME}>
          <ArrowTest className="rotate-180" />
        </Button>
        <div />

        <Button onClick={() => handleClick(Direction.LEFT)} className={BUTTON_CLASSNAME}>
          <ArrowTest className="rotate-90" />
        </Button>
        <div className="bg-white" />
        <Button onClick={() => handleClick(Direction.RIGHT)} className={BUTTON_CLASSNAME}>
          <ArrowTest className="-rotate-90" />
        </Button>

        <div />
        <Button onClick={() => handleClick(Direction.DOWN)} className={BUTTON_CLASSNAME}>
          <ArrowTest />
        </Button>
        <div />
      </div>
    </div>
  );
};
