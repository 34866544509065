import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import {
  asyncGetUser,
  asyncClaimTokens,
  asyncUpdateBalance,
  asyncClaimBonus,
  setIsFirstLoading,
  asyncBoostActive,
  asyncClaimDailyReward,
  setUser,
  authUser,
  asyncAutoBotClaimToken
} from './action';
import { UserSliceState } from './slice';
import { User } from '../../types/users.interfaces';

export function authUserReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(authUser.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    authUser.fulfilled,
    (state, { payload }: PayloadAction<{ accessToken: string }>) => {
      localStorage.setItem('token', payload.accessToken);

      state.isLoading = false;
    }
  );

  builder.addCase(authUser.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getUserReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(asyncGetUser.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    asyncGetUser.fulfilled,
    (state, { payload }: PayloadAction<{ user: User; serverTime: string }>) => {
      state.user = payload.user;
      state.serverTime = payload.serverTime;
      state.isLoading = false;
      state.isAuth = true;
    }
  );

  builder.addCase(asyncGetUser.rejected, (state) => {
    state.isLoading = false;
    state.isAuth = false;
  });
}

// TODO - create a separate action/reducer - setUser

export function claimTokensReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(asyncClaimTokens.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncClaimTokens.fulfilled, (state, { payload }: PayloadAction<User>) => {
    state.user = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncClaimTokens.rejected, (state) => {
    state.isLoading = false;
  });
}

export function claimBonusReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(asyncClaimBonus.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncClaimBonus.fulfilled, (state, { payload }: PayloadAction<User>) => {
    state.user = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncClaimBonus.rejected, (state) => {
    state.isLoading = false;
  });
}

export function asyncClaimDailyRewardReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(asyncClaimDailyReward.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncClaimDailyReward.fulfilled, (state, { payload }: PayloadAction<User>) => {
    state.user = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncClaimDailyReward.rejected, (state) => {
    state.isLoading = false;
  });
}

export function updateBalanceReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(asyncUpdateBalance.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncUpdateBalance.fulfilled, (state, { payload }: PayloadAction<User>) => {
    state.user = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncUpdateBalance.rejected, (state) => {
    state.isLoading = false;
  });
}

export function boostActiveReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(asyncBoostActive.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncBoostActive.fulfilled, (state, { payload }: PayloadAction<User>) => {
    state.user = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncBoostActive.rejected, (state) => {
    state.isLoading = false;
  });
}

export function isFirstLoadingReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(setIsFirstLoading, (state, action: PayloadAction<boolean>) => {
    state.isFirstLoading = action.payload;
  });
}

export function setUserReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(setUser, (state, action: PayloadAction<User>) => {
    state.user = action.payload;
  });
}

export function autoBotClaimIncome(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(
    asyncAutoBotClaimToken.fulfilled,
    (state, { payload }: PayloadAction<{ income: number }>) => {
      state.autoBotIncome = payload.income;
    }
  );
}
