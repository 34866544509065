import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { buyLuckyGameTickets } from '../../../model/lucky-games/action';
import { selectLuckyGameCombinationsLeft } from '../../../model/lucky-games/selectors';
import { selectClaimedBalance } from '../../../model/user/selectors';
import { getLuckyGameBuyTicketsError } from '../../utils/titles';
import { getLuckyGamePageUrl } from '../../utils/url';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import { useBoolean } from '../useBoolean';

export interface BuyLuckyGameTicketsModalProps {
  id: string;
  price: number;
  ticketsCount: number;
}

export const useBuyLuckyGameTickets = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const claimedBalance = useAppSelector(selectClaimedBalance);
  const combinationsLeft = useAppSelector(selectLuckyGameCombinationsLeft);

  const [isLoading, handleStartLoading, handleStopLoading] = useBoolean();
  const [
    isModalBuyTicketsOpen,
    handleOpenBuyTicketsModal,
    handleCloseBuyTicketsModal,
    buyTicketsModalProps
  ] = useBoolean<BuyLuckyGameTicketsModalProps>();

  const handleBuyLuckyGameTickets = async () => {
    const id = buyTicketsModalProps?.id;

    if (!id) return;

    if (combinationsLeft < buyTicketsModalProps?.ticketsCount) {
      toast.error(getLuckyGameBuyTicketsError(combinationsLeft));
      return;
    }

    handleStartLoading();
    dispatch(buyLuckyGameTickets(buyTicketsModalProps))
      .unwrap()
      .then(() => {
        navigate(getLuckyGamePageUrl(id));
        handleCloseBuyTicketsModal();
      })
      .finally(handleStopLoading);
  };

  const onOpenBuyModal = (modalProps: BuyLuckyGameTicketsModalProps) => {
    if (claimedBalance && claimedBalance < modalProps?.price) {
      toast.error('Insufficient funds');
      return;
    }

    handleOpenBuyTicketsModal(modalProps);
  };

  return {
    isLoading,
    handleBuyLuckyGameTickets,
    handleOpenBuyModal: onOpenBuyModal,
    handleCloseBuyTicketsModal,
    isModalBuyTicketsOpen,
    buyTicketsModalProps
  };
};
