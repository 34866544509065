import { useState, useEffect } from 'react';

import { calculateTimeLeft } from '../utils/times';

export const useCountdown = (endDate: string) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endDate]);

  return timeLeft;
};
