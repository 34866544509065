import { useEffect } from 'react';

import { getLuckyGamesResults, useAppDispatch } from 'shared';

import { GameResultsList } from './GameResultList';

export const LotteryGameResults = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getLuckyGamesResults());
  }, [dispatch]);

  return <GameResultsList />;
};
