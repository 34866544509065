import { isEmpty } from 'lodash';

import { selectGamesResults, selectIsGamesResultsLoading, useAppSelector } from 'shared';
import { SkeletonLotteryResultsList } from 'widgets/skeleton';

import { GameResultsBlock } from './GameResultsBlock';

export const GameResultsList = () => {
  const results = useAppSelector(selectGamesResults);
  const isLoading = useAppSelector(selectIsGamesResultsLoading);

  const resultsKeys = Object.keys(results || {});

  if (isLoading) return <SkeletonLotteryResultsList />;

  if (isEmpty(results))
    return <div className="text-center">You did not participate in the lottery</div>;

  return (
    <>
      {resultsKeys.map((date, index) => (
        <GameResultsBlock key={date + index} date={date} gameData={results[date]} />
      ))}
    </>
  );
};
