export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESSFULLY = 'successfully',
  TRANSPARENT = 'transparent',
  DISABLED_PRIMARY = 'disabled-primary',
  DISABLED_SECONDARY = 'disabled-secondary'
}

export const ButtonVariantsStyles: { [key in ButtonVariants]: string } = {
  [ButtonVariants.PRIMARY]:
    'bg-grey text-xs px-6 text-white disabled:opacity-70 active:bg-[#414041]',
  [ButtonVariants.SECONDARY]:
    'w-full h-16 bg-yellow font-bold text-xl rounded-2xl text-dark-100 active:scale-95 disabled:opacity-70',
  [ButtonVariants.SUCCESSFULLY]: 'bg-success-linear px-6 text-white text-xs',
  [ButtonVariants.TRANSPARENT]: 'bg-transparent',
  [ButtonVariants.DISABLED_PRIMARY]:
    'opacity-70 bg-grey text-xs px-6 text-white pointer-events-none',
  [ButtonVariants.DISABLED_SECONDARY]:
    'opacity-70 w-full h-16 bg-yellow font-bold text-xl rounded-2xl text-dark-100 pointer-events-none'
};
