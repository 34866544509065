import { useLuckyGame, Typography, getTelegramApi } from '../shared';
import { TypographyVariants } from '../shared/constants/typography';
import { LuckyGameWidget } from '../widgets/games';

export const LuckyGame = () => {
  useLuckyGame();

  const tg = getTelegramApi();

  tg.BackButton.isVisible = true;

  return (
    <div className="space-y-4">
      <Typography className="text-center" tag="h1" variant={TypographyVariants.LARGE_TITLE}>
        LUCKY GAME
      </Typography>
      <LuckyGameWidget />
    </div>
  );
};
