import { useCallback, useEffect, useRef, useState } from 'react';

import { GameStatusModal } from 'features/game-status-modal';
import { useAppDispatch } from 'shared';
import { GameStatus } from 'shared/constants/games';
import { setGameReward } from 'shared/model/games/action';

import { Controller } from './Controller';
import { CANVAS_SIZE, Direction, ITEM_SIZE, SNAKE_INIT } from '../constants/constants';

interface Position {
  x: number;
  y: number;
}

const postionGenerate = () => {
  const x = Math.floor(Math.random() * 20) * ITEM_SIZE;
  const y = Math.floor(Math.random() * 20) * ITEM_SIZE;

  return { x, y };
};

export const GameSnake = () => {
  const dispatch = useAppDispatch();

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const context = useRef<CanvasRenderingContext2D | null>(null);

  const [direction, setDirection] = useState<Direction>(Direction.RIGHT);

  const [score, setScore] = useState<number>(0);
  const [isGameOver, setIsGameOver] = useState<boolean>(false);

  const [snakeBody, setSnakeBody] = useState<Position[]>(SNAKE_INIT);

  const [foodCoordinate, setFoodCoordinate] = useState<Position>(postionGenerate());

  useEffect(() => {
    if (canvasRef.current === null) {
      throw new Error('canvasRef is not used');
    }

    context.current = canvasRef.current.getContext('2d');

    if (context.current === null) {
      throw new Error('canvasRef is not used');
    }

    const ctx = context.current;

    canvasRef.current.width = CANVAS_SIZE;
    canvasRef.current.height = CANVAS_SIZE;

    const draw = (position: Position, size: number) => {
      ctx.fillRect(position.x, position.y, size, size);
    };

    ctx.fillStyle = '#F10750';
    draw(foodCoordinate, ITEM_SIZE);

    snakeBody?.forEach((segment, index) => {
      if (index % 2 === 0) ctx.fillStyle = '#A1CCA5';
      else ctx.fillStyle = '#7FFFD4';

      draw(segment, ITEM_SIZE);
    });
  }, [snakeBody]);

  const move = useCallback(() => {
    if (!snakeBody) return;

    const bodyCopy = [...snakeBody];
    const head = bodyCopy[bodyCopy.length - 1];
    let newBody = [];

    switch (direction) {
      case Direction.RIGHT:
        newBody = [
          ...bodyCopy,
          { ...head, x: head.x >= CANVAS_SIZE - ITEM_SIZE - 1 ? 0 : head.x + ITEM_SIZE }
        ];
        break;
      case Direction.LEFT:
        newBody = [
          ...bodyCopy,
          {
            ...head,
            x: head.x + ITEM_SIZE <= 0 + ITEM_SIZE ? ITEM_SIZE * 20 : head.x - ITEM_SIZE
          }
        ];
        break;
      case Direction.DOWN:
        newBody = [
          ...bodyCopy,
          {
            ...head,
            y: head.y + ITEM_SIZE >= CANVAS_SIZE - 1 ? 0 : head.y + ITEM_SIZE
          }
        ];
        break;
      case Direction.UP:
        newBody = [
          ...bodyCopy,
          {
            ...head,
            y: head.y + ITEM_SIZE <= 0 + ITEM_SIZE + 1 ? ITEM_SIZE * 20 : head.y - ITEM_SIZE
          }
        ];
        break;
    }

    const newHead = newBody[newBody.length - 1];

    if (newHead.x === foodCoordinate.x && newHead.y === foodCoordinate.y) {
      newBody.unshift({ x: newBody[0].x - ITEM_SIZE, y: newBody[0].y });
      setFoodCoordinate(postionGenerate());

      setScore((prev) => prev + 1 * 10);
    }

    newBody.forEach((segment, index) => {
      if (index === newBody.length - 1) {
        return;
      }
      if (segment.x === newHead.x && segment.y === newHead.y) {
        setIsGameOver(true);
      }
    });

    setSnakeBody(newBody);
    newBody.shift();
  }, [setSnakeBody, snakeBody, isGameOver]);

  useEffect(() => {
    const snakeTimeout = setTimeout(() => {
      requestAnimationFrame(move);
    }, 150 - (30 + score / 2) / 5);

    if (isGameOver) {
      dispatch(setGameReward(score));

      clearTimeout(snakeTimeout);
    }

    return () => clearTimeout(snakeTimeout);
  }, [move, snakeBody]);

  const restart = () => {
    setSnakeBody(SNAKE_INIT);
    setIsGameOver(false);
    setScore(0);
    setDirection(Direction.RIGHT);
    setFoodCoordinate(postionGenerate());
  };

  return (
    <div className="h-full">
      <p>Score {score}</p>

      <div className="relative">
        <canvas ref={canvasRef} className="bg-white" width={CANVAS_SIZE} height={CANVAS_SIZE} />

        {isGameOver && <GameStatusModal status={GameStatus.LOST} handleClick={restart} />}
      </div>

      <Controller currentDirection={direction} setDirection={setDirection} />
    </div>
  );
};
