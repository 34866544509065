import React, { forwardRef, InputHTMLAttributes } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'radio' | 'checkbox';
  label?: string;
  className?: string;
}

export const SwitchableInput = forwardRef<HTMLInputElement, Props>(
  ({ type = 'checkbox', label, className, ...props }, ref) => {
    return (
      <div className="inline-flex items-center">
        <label className="flex items-center cursor-pointer relative w-full aspect-square">
          <input
            ref={ref}
            className={twMerge(
              'peer w-full h-full cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md bg-gray-600 checked:bg-green-500',
              className
            )}
            type={type}
            {...props}
          />
          {label && (
            <span className="absolute text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none">
              {label}
            </span>
          )}
        </label>
      </div>
    );
  }
);

SwitchableInput.displayName = 'SwitchableInput';
