import { useNavigate } from 'react-router-dom';

import { buyLuckyGameAccess } from '../../../model/lucky-games/action';
import { getLuckyGamePageUrl } from '../../utils/url';
import { useAppDispatch } from '../useAppDispatch';
import { useBoolean } from '../useBoolean';

export interface BuyLuckyGameAccessModalProps {
  id: string;
}

export const useBuyLuckyGameAccess = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, handleStartLoading, handleStopLoading] = useBoolean();
  const [isModalBuyOpen, handleOpenBuyModal, handleCloseBuyModal, buyModalProps] =
    useBoolean<BuyLuckyGameAccessModalProps>();

  const handleBuyLuckyGameAccess = async () => {
    const id = buyModalProps?.id;

    if (!id) return;

    handleStartLoading();
    dispatch(buyLuckyGameAccess(id))
      .unwrap()
      .then(() => {
        navigate(getLuckyGamePageUrl(id));
      })
      .finally(handleStopLoading);
  };

  return {
    isLoading,
    handleBuyLuckyGameAccess,
    handleOpenBuyModal,
    handleCloseBuyModal,
    isModalBuyOpen,
    buyModalProps
  };
};
