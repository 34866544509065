import { Skeleton } from '../Skeleton';

export const SkeletonLotteryResultsList = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <Skeleton className="h-7 w-1/2 rounded-md" />
        <Skeleton className="w-full h-40 rounded-md" />
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton className="h-7 w-1/2 rounded-md" />
        <Skeleton className="w-full h-64 rounded-md" />
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton className="h-7 w-1/2 rounded-md" />
        <Skeleton className="w-full h-32 rounded-md" />
      </div>
    </div>
  );
};
