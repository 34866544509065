import { Typography } from 'shared/components/Typography';
import { TypographyVariants } from 'shared/constants/typography';

import { SkeletonGameCard } from '../games-list/SkeletonGameCard';

export const SkeletonBoostsList = () => {
  return (
    <div className="mt-8 space-y-8">
      <div>
        <Typography className="text-[#858994]" tag="h5" variant={TypographyVariants.MEDIUM_TITLE}>
          Boosts
        </Typography>
        <div className="mt-4 space-y-1.5">
          {Array.from(Array(2).keys()).map((key) => (
            <SkeletonGameCard key={key} />
          ))}
        </div>
      </div>
      <div>
        <Typography className="text-[#858994]" tag="h5" variant={TypographyVariants.MEDIUM_TITLE}>
          Upgrades
        </Typography>
        <div className="mt-4 space-y-1.5">
          {Array.from(Array(7).keys()).map((key) => (
            <SkeletonGameCard key={key} />
          ))}
        </div>
      </div>
    </div>
  );
};
