import React from 'react';

/* eslint-disable import/no-unresolved */
import { useParams } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

import { Button, Modal, Slider, useBuyLuckyGameTickets, WithTitleBlock } from 'shared';
import { ButtonVariants } from 'shared/constants/button';
import { LUCKY_GAME_TICKETS_VALUES } from 'shared/constants/lucky-game';

import { LuckyGameBuyTicketsCard } from '../../index';

interface Props {
  ticketPrice: number;
}

const LuckyGameBuyTickets = ({ ticketPrice }: Props) => {
  const { id } = useParams();

  const {
    isLoading: isBuyingAccess,
    isModalBuyTicketsOpen,
    handleOpenBuyModal,
    handleBuyLuckyGameTickets,
    handleCloseBuyTicketsModal,
    buyTicketsModalProps
  } = useBuyLuckyGameTickets();

  const { ticketsCount } = buyTicketsModalProps || {};

  return (
    <div>
      <Modal
        header={`Are you sure you want to buy ${ticketsCount} ${
          (ticketsCount || 0) > 1 ? 'tickets' : 'ticket'
        }?`}
        isOpened={isModalBuyTicketsOpen}
        handleClose={handleCloseBuyTicketsModal}
        height={40}
      >
        <div className="h-full flex items-end">
          <Button
            variant={ButtonVariants.SECONDARY}
            isLoading={isBuyingAccess}
            onClick={handleBuyLuckyGameTickets}
            className="mb-6"
          >
            {`PAY ${buyTicketsModalProps?.price}$COI`}
          </Button>
        </div>
      </Modal>

      <WithTitleBlock
        title="Buy in one click"
        description="Tickets with random numbers"
        childrenClassName="mt-1.5"
      >
        <div className="h-40">
          <Slider>
            {LUCKY_GAME_TICKETS_VALUES.map((count) => (
              <SwiperSlide key={count}>
                <LuckyGameBuyTicketsCard
                  gameId={id}
                  count={count}
                  price={count * ticketPrice}
                  handleBuyTickets={handleOpenBuyModal}
                />
              </SwiperSlide>
            ))}
          </Slider>
        </div>
      </WithTitleBlock>
    </div>
  );
};

export default LuckyGameBuyTickets;
