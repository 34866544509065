import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { TYPOGRAPHY_STYLES, TypographyVariants } from 'shared/constants/typography';

interface Props {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  className?: string;
  children: ReactNode | string;
  variant?: TypographyVariants;
}

export const Typography = ({
  tag = 'p',
  className,
  children,
  variant = TypographyVariants.NORMAL
}: Props) => {
  const TypographyTag = tag;

  return (
    <TypographyTag className={twMerge('text-white', TYPOGRAPHY_STYLES[variant], className)}>
      {children}
    </TypographyTag>
  );
};
