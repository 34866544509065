import React from 'react';

import { ReactComponent as TicketsIcon } from 'assets/svg/tickets.svg';
import { Block, Button, BuyLuckyGameTicketsModalProps } from 'shared';
import { Typography } from 'shared/components/Typography';
import { ButtonVariants } from 'shared/constants/button';
import { TypographyVariants } from 'shared/constants/typography';

interface Props {
  gameId?: string;
  count: number;
  price: number;
  handleBuyTickets: (values: BuyLuckyGameTicketsModalProps) => void;
}

const LuckyGameBuyTicketsCard = ({ gameId, count, price, handleBuyTickets }: Props) => {
  const onClickBuyTickets = () => {
    if (!gameId) return;

    handleBuyTickets({ id: gameId, price, ticketsCount: count });
  };

  return (
    <Block className="relative">
      <TicketsIcon className="absolute -top-2.5 left-[calc(50%-12px)]" />
      <Typography className="text-center" variant={TypographyVariants.MEDIUM_TITLE}>
        {count} {count > 1 ? 'Tickets' : 'Ticket'}
      </Typography>
      <div className="flex justify-between items-center mt-6">
        <Typography className="text-sm">{price} $COI</Typography>
        <Button
          onClick={onClickBuyTickets}
          className="leading-5 p-1 px-2"
          variant={ButtonVariants.PRIMARY}
        >
          Buy
        </Button>
      </div>
    </Block>
  );
};

export default LuckyGameBuyTicketsCard;
