import { useState, useEffect, useCallback } from 'react';

import { generateRandomNumbers } from '../utils/array-generators';

interface UseRandomNumbersProps {
  min: number;
  max: number;
  onIteration: (values: number[]) => void;
  maxNumbers: number;
  shouldBeUnique?: boolean;
}

export const useRandomNumbersWithIterations = ({
  min,
  max,
  onIteration,
  maxNumbers,
  shouldBeUnique
}: UseRandomNumbersProps) => {
  const [numbers, setNumbers] = useState<number[]>([]);
  const [_, setCount] = useState(0);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const startGenerating = useCallback(() => {
    if (timerId) {
      clearInterval(timerId);
      setTimerId(null);
    }

    const uniqueNumbers = generateRandomNumbers({ maxNumbers, max, min, unique: shouldBeUnique });
    setCount(0);
    setNumbers([]);
    setIsGenerating(true);

    const id = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount >= uniqueNumbers.length) {
          setIsGenerating(false);
          clearInterval(id);
          setTimerId(null);
          return prevCount;
        }

        const newNumber = uniqueNumbers[prevCount];
        setNumbers((prevNumbers) => {
          const updatedNumbers = [...prevNumbers, newNumber];
          onIteration(updatedNumbers);
          return updatedNumbers;
        });

        return prevCount + 1;
      });
    }, 300);

    setTimerId(id);
  }, [max, maxNumbers, min, onIteration, timerId]);

  useEffect(() => {
    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [timerId]);

  return { numbers, startGenerating, isGenerating };
};
