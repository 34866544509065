import cn from 'classnames';
import { twJoin } from 'tailwind-merge';

import { LuckyGameCardCombinationNumber } from './LuckyGameCardCombinationNumber';
import { LUCKY_GAME_TYPES, Typography } from '../../../shared';

interface Props {
  selectedNumbers: number[];
  bonusNumber: number | null;
  type: LUCKY_GAME_TYPES;
  className?: string;
  winCombinations?: number[];
}

export const LuckyGameCardCombinationNumbers = ({
  className,
  selectedNumbers,
  bonusNumber,
  type,
  winCombinations
}: Props) => {
  const isBonusNumberShown = type === LUCKY_GAME_TYPES.GAME2;

  return (
    <div className={twJoin('flex items-center space-x-1 ml-2', className)}>
      {selectedNumbers?.map((key, index) => (
        <LuckyGameCardCombinationNumber
          className={cn({ 'bg-green-500': winCombinations?.includes(key) })}
          key={index}
        >
          {key}
        </LuckyGameCardCombinationNumber>
      ))}
      {isBonusNumberShown && (
        <div className="flex items-center">
          <Typography className="text-xl mr-1">+</Typography>
          <LuckyGameCardCombinationNumber>{bonusNumber}</LuckyGameCardCombinationNumber>
        </div>
      )}
    </div>
  );
};
