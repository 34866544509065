import { useEffect } from 'react';

import { isNil } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as LotteryIcon } from 'assets/svg/activity/lottery.svg';
import { FlappyBird } from 'features/flappy-bird';
import { Game2048 } from 'features/game-2048';
import { GameSnake } from 'features/game-snake';
import {
  AppRoutes,
  asyncGameClaimReward,
  Button,
  getTelegramApi,
  setGameReward,
  useAppDispatch,
  useAppSelector
} from 'shared';
import { InfoBlock } from 'shared/components/InfoBlock';
import { Loader } from 'shared/components/Loader';
import { ButtonVariants } from 'shared/constants/button';
import { Games, GAMES_ICONS } from 'shared/constants/games';
import { useGetCurrentUpgrade } from 'shared/lib/hooks/useGetСurrentUpgrade';
import { selectGames, selectGameReward } from 'shared/model/games/selectors';
import { UpgradeTypes } from 'shared/types/boosters.interfaces';

const gameQueryParam = 'game';

export const GameCenter = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const gameReward = useAppSelector(selectGameReward);
  const tg = getTelegramApi();

  const [searchParam, setSearchParam] = useSearchParams();

  const currentGameReward = useGetCurrentUpgrade(UpgradeTypes.GAMES_REWARDS);

  const games = useAppSelector(selectGames);

  const activeGame = searchParam.get(gameQueryParam);

  const handleClick = (active_game: Games) => {
    setSearchParam({ game: active_game });
  };

  useEffect(() => {
    if (activeGame) {
      tg.BackButton.isVisible = true;
    }
  }, [activeGame]);

  useEffect(() => {
    if (!currentGameReward || gameReward === 0) return;

    const game = games?.find((item) => item.path === activeGame);

    if (!game) return;

    dispatch(
      asyncGameClaimReward({ game_id: game.id, score: gameReward * currentGameReward?.multiplier })
    ).finally(() => dispatch(setGameReward(0)));
  }, [gameReward]);

  if (!games) return <Loader />;

  return (
    <>
      {isNil(searchParam.get(gameQueryParam)) && (
        <div className="space-y-1.5 mt-8">
          {games.map(({ title, path }) => {
            const Icon = GAMES_ICONS[path];
            return (
              <InfoBlock
                key={title}
                title={title}
                leftSideContent={<Icon className="w-[26px] h-[26px] fill-white" />}
              >
                <Button
                  onClick={() => handleClick(path)}
                  variant={ButtonVariants.PRIMARY}
                  className="w-20 leading-5"
                >
                  Play
                </Button>
              </InfoBlock>
            );
          })}
          <InfoBlock
            title="Lucky Games"
            leftSideContent={<LotteryIcon className="w-[26px] h-[26px] fill-white" />}
          >
            <Button
              onClick={() => navigate(AppRoutes.LUCKY_GAMES)}
              variant={ButtonVariants.PRIMARY}
              className="w-20 leading-5"
            >
              Play
            </Button>
          </InfoBlock>
        </div>
      )}

      {activeGame === Games.SNAKE && <GameSnake />}
      {activeGame === Games.GAME_2048 && <Game2048 />}
      {activeGame === Games.FLAPPY_BIRD && <FlappyBird />}
    </>
  );
};
