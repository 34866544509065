import { ReactComponent as CheckedIcon } from 'assets/svg/checked.svg';
import { Button, SVGComponent } from 'shared';
import { InfoBlock } from 'shared/components/InfoBlock';
import { ButtonVariants } from 'shared/constants/button';

interface Props {
  icon: SVGComponent;
  title: string;
  usedCount: number | null;
  maxAvailable: number;
  description: string;
  buttonText: string;
  type: string;
  usedText?: string;
  userId?: number;
  handleClick?: VoidFunction;
  isAvailable?: boolean;
}

export const BoostCard = ({
  icon: Icon,
  title,
  usedCount,
  maxAvailable,
  description,
  buttonText,
  usedText,
  // type,
  // userId,
  handleClick,
  isAvailable
}: Props) => {
  // const handleClick = (type: string) => {
  //   dispatch(asyncBoostActive({ boostType: type, userId }));
  // };

  return (
    <InfoBlock
      title={title}
      description={`${usedCount ?? maxAvailable} / ${maxAvailable} ${description}`}
      leftSideContent={<Icon className="w-[26px] h-[26px]" />}
    >
      {usedCount !== null ? (
        <div className="bg-success-linear rounded-full flex justify-center items-center gap-1 w-[76px] leading-5">
          <span>{usedText}</span>
          <CheckedIcon />
        </div>
      ) : (
        <Button onClick={handleClick} variant={ButtonVariants.PRIMARY} className="leading-5">
          {isAvailable ? buttonText : 'Unlock'}
        </Button>
      )}
    </InfoBlock>
  );
};
