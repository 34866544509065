export enum TypographyVariants {
  LARGE_TITLE = 'large_title',
  SEMILARGE_TITLE = 'semibold_title',
  MEDIUM_TITLE = 'medium_title',
  NORMAL = 'normal'
}

export const TYPOGRAPHY_STYLES = {
  [TypographyVariants.LARGE_TITLE]: 'text-2xl font-bold',
  [TypographyVariants.SEMILARGE_TITLE]: 'text-xl font-bold',
  [TypographyVariants.MEDIUM_TITLE]: 'text-lg font-bold',
  [TypographyVariants.NORMAL]: 'text-base font-normal'
};
