import { Board2048 } from './Board2048';
import GameProvider from '../context/game-2048-context';

export const Game2048 = () => {
  return (
    <GameProvider>
      <Board2048 />
    </GameProvider>
  );
};
