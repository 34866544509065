import React from 'react';

import { ButtonVariants } from 'shared/constants/button';

import { Button, Modal, UseClearLuckyGameCombinationValues } from '../../../../shared';

type Props = Omit<UseClearLuckyGameCombinationValues, 'handleOpenClearModal'>;

const LuckyGameClearCombinationModal = ({
  isClearModalOpen,
  handleClearLuckyGameCombination,
  handleCloseClearModal,
  isLoading
}: Props) => {
  return (
    <Modal
      header="Are you sure you want to clear this combination?"
      isOpened={isClearModalOpen}
      handleClose={handleCloseClearModal}
      height={40}
    >
      <div className="h-full flex items-end">
        <Button
          variant={ButtonVariants.SECONDARY}
          isLoading={isLoading}
          onClick={handleClearLuckyGameCombination}
          className="mb-6"
        >
          Clear
        </Button>
      </div>
    </Modal>
  );
};

export default LuckyGameClearCombinationModal;
