import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../app';

const selectState = (x: RootState) => x.luckyGamesReducer;

export const selectLuckyGamesWithLoading = createSelector(
  selectState,
  ({ games, isGamesLoading }) => ({
    games,
    isGamesLoading
  })
);

export const selectLuckyGame = createSelector(selectState, ({ game, isGameLoading }) => ({
  data: game,
  isGameLoading
}));

export const selectLuckyGameCombinationsLeft = createSelector(
  selectState,
  ({ game }) => 100 - (game?.combinations?.length || 0)
);

export const selectGamesResults = createSelector(selectState, ({ results }) => results);
export const selectIsGamesResultsLoading = createSelector(
  selectState,
  ({ isResultsLoading }) => isResultsLoading
);

export const selectGameResult = createSelector(selectState, ({ result }) => result);
export const selectIsGameResultLoading = createSelector(
  selectState,
  ({ isResultLoading }) => isResultLoading
);
