import { ReactComponent as TicketIcon } from 'assets/svg/ticket.svg';
import { LuckyGameCardCombinationNumbers } from 'entities/games';
import { LotteryResultCard } from 'entities/lottery-game-results';
import { Button, selectGameResult, selectIsGameResultLoading, useAppSelector } from 'shared';
import { Loader } from 'shared/components/Loader';
import { ButtonVariants } from 'shared/constants/button';
import { useGetCurrentUpgrade } from 'shared/lib/hooks/useGetСurrentUpgrade';
import { UpgradeTypes } from 'shared/types/boosters.interfaces';

interface Props {
  claimReward: (lotteryId: string) => void;
}

export const GameResultModalContent = ({ claimReward }: Props) => {
  const lotteryResult = useAppSelector(selectGameResult);
  const isLoading = useAppSelector(selectIsGameResultLoading);

  const lotteryRewardMultiplier =
    useGetCurrentUpgrade(UpgradeTypes.LOTTERY_REWARD)?.multiplier || 1;

  if (isLoading || !lotteryResult)
    return (
      <div className="flex h-full justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="text-white flex flex-col gap-2 h-5/6 overflow-y-auto">
        {lotteryResult?.combinations?.map((combination, index) => {
          return (
            <LotteryResultCard key={index}>
              <div className="flex gap-2 items-center text-white">
                {index + 1} <TicketIcon />
              </div>
              <LuckyGameCardCombinationNumbers
                selectedNumbers={combination.selected_combination_numbers}
                bonusNumber={combination.bonus_number}
                type={lotteryResult.lottery_id.type}
                className="flex-1 odd:bg-green"
                winCombinations={lotteryResult?.lottery_id?.win_combinations}
              />
            </LotteryResultCard>
          );
        })}
      </div>
      <Button
        onClick={() => claimReward(lotteryResult?.lottery_id?.id)}
        disabled={lotteryResult?.claimed}
        variant={ButtonVariants.SECONDARY}
      >
        CLAIM {lotteryResult?.winning_amount * lotteryRewardMultiplier}
      </Button>
    </div>
  );
};
