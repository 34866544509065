import { Skeleton } from 'widgets/skeleton/components/Skeleton';

const LuckyGameSkeleton = () => {
  return (
    <div>
      <Skeleton className="h-7 w-2/6 rounded-md" />
      <Skeleton className="h-6 w-3/6 mt-1 rounded-md" />
      <div className="mt-4">
        <div className="flex items-center space-x-3.5">
          <Skeleton className="w-1/2 h-28 rounded-xl" />
          <Skeleton className="w-1/2 h-28 rounded-xl" />
        </div>
        <div className="flex justify-center items-center mt-4 space-x-1">
          {Array.from(Array(6).keys()).map((key) => (
            <Skeleton key={key} className="w-2.5 h-2.5 rounded-full" />
          ))}
        </div>
      </div>
      <div className="mt-7">
        <Skeleton className="h-7 w-2/6 rounded-md" />
        <div className="flex items-center mt-4 space-x-3.5">
          {Array.from(Array(3).keys()).map((key) => (
            <Skeleton key={key} className="w-2/6 h-[42px] rounded-xl" />
          ))}
        </div>
        <div className="mt-4">
          <Skeleton className="h-6 w-3/6 rounded-md" />
          <div className="mt-4 flex flex-col gap-3.5">
            {Array.from(Array(3).keys()).map((rowKey) => (
              <div className="flex gap-3.5" key={rowKey}>
                {Array.from(Array(8).keys()).map((key) => (
                  <Skeleton key={key} className="w-8 h-8 rounded" />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Skeleton className="h-7 w-3/6 rounded-md" />
        <div className="mt-4 space-y-1">
          {Array.from(Array(3).keys()).map((key) => (
            <Skeleton key={key} className="w-full h-[30px] rounded" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LuckyGameSkeleton;
