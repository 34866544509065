import { LUCKY_GAME_TYPES } from '../types/lucky-games.interfaces';

export const LUCKY_GAME_TICKETS_VALUES = [1, 3, 6, 9, 12, 15];

export const CHOOSE_NUMBERS_COUNT: Record<
  LUCKY_GAME_TYPES,
  {
    label: number | string;
    formValue: number;
  }
> = {
  [LUCKY_GAME_TYPES.GAME1]: { label: 6, formValue: 6 },
  [LUCKY_GAME_TYPES.GAME2]: { label: 6, formValue: 6 },
  [LUCKY_GAME_TYPES.GAME3]: { label: 5, formValue: 5 },
  [LUCKY_GAME_TYPES.GAME4]: { label: 3, formValue: 1 }
};

export const getLuckyGameAreaFieldValidation = (gameType: LUCKY_GAME_TYPES, isBonus?: boolean) => ({
  required: 'Required',
  validate: (value: string[]) => {
    const numbersCount = isBonus ? 1 : CHOOSE_NUMBERS_COUNT[gameType]?.formValue;

    return value?.length >= numbersCount || `${numbersCount} numbers should be selected`;
  }
});
