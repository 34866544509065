import { useEffect } from 'react';

import { selectLuckyGamesWithLoading, getLuckyGames } from '../../../index';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';

export const useLuckyGames = () => {
  const dispatch = useAppDispatch();

  const luckyGamesData = useAppSelector(selectLuckyGamesWithLoading);

  useEffect(() => {
    dispatch(getLuckyGames());
  }, [dispatch]);

  return { ...luckyGamesData };
};
