import { combineReducers, configureStore } from '@reduxjs/toolkit';

import {
  userReducer,
  taskReducer,
  tierReducer,
  leaderboardReducer,
  bonusReducer,
  rewardsReducer,
  boostersReducer,
  gamesReducer,
  luckyGamesReducer
} from 'shared';

const rootReducer = combineReducers({
  userReducer,
  taskReducer,
  tierReducer,
  leaderboardReducer,
  bonusReducer,
  rewardsReducer,
  boostersReducer,
  gamesReducer,
  luckyGamesReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
