import React from 'react';

import {
  LUCKY_GAME_TYPES,
  LuckyCombination,
  Typography,
  WithTitleBlock,
  useClearLuckyGameCombination
} from 'shared';

import { LuckyGameCardCombinationNumbers } from '../../../../entities/games';
import { LuckyGameClearCombinationModal, LuckyGameCombinationsCard } from '../../index';

interface Props {
  combinations: LuckyCombination[];
  type: LUCKY_GAME_TYPES;
}

const LuckyGameCombinations = ({ combinations, type }: Props) => {
  const { handleOpenClearModal, ...clearModalProps } = useClearLuckyGameCombination();

  return (
    <>
      <LuckyGameClearCombinationModal {...clearModalProps} />
      <WithTitleBlock title="Combinations:">
        <div className="space-y-4">
          {combinations?.length ? (
            combinations?.map((combination, index) => (
              <LuckyGameCombinationsCard
                key={combination.id}
                position={index + 1}
                handleClear={handleOpenClearModal}
                {...combination}
              >
                <LuckyGameCardCombinationNumbers
                  selectedNumbers={combination?.selected_combination_numbers}
                  bonusNumber={combination?.bonus_number}
                  type={type}
                />
              </LuckyGameCombinationsCard>
            ))
          ) : (
            <Typography>There are currently no combinations</Typography>
          )}
        </div>
      </WithTitleBlock>
    </>
  );
};

export default LuckyGameCombinations;
