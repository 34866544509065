import { useMemo } from 'react';

import { Button, SVGComponent, useAppDispatch } from 'shared';
import { InfoBlock } from 'shared/components/InfoBlock';
import { ButtonVariants } from 'shared/constants/button';
import { Upgrade, UpgradeData, UpgradeLevelPayload } from 'shared/types/boosters.interfaces';

interface Props {
  icon: SVGComponent;
  upgrade?: Upgrade;
  level?: number;
  handleClick: (values: UpgradeData) => void; // custom click button
}

export const UpgradeCard = ({ icon: Icon, upgrade, level, handleClick }: Props) => {
  const dispatch = useAppDispatch();

  const { title = '', levels = [] } = upgrade || {};

  const levelInfo = useMemo(() => {
    if (!level) return;

    return levels.find(({ level: listLevel }) => listLevel === level);
  }, [level, levels]);

  const maxAvailableLevel = levels[levels?.length - 1]?.level;

  const isReachedMaxLevel = maxAvailableLevel === levelInfo?.level;

  const description = `${levelInfo?.multiplier} x ${levelInfo?.level} LVL`;

  // const handleClick = () => {
  //   if (isReachedMaxLevel || !userId || !upgrade) return;
  //
  //   dispatch(asyncUpgradeLevel({ userId, upgradeId: upgrade.id, label: upgrade?.title }));
  // };

  const onButtonClick = () => {
    if (!upgrade) return;

    handleClick({
      upgradeId: upgrade?.id,
      label: upgrade?.title,
      levels: upgrade?.levels,
      nextLevel: levels[level || 0] || {}
    });
  };

  return (
    <InfoBlock
      title={title}
      description={description}
      leftSideContent={<Icon className="w-[26px] h-[26px]" />}
    >
      <Button
        disabled={isReachedMaxLevel}
        onClick={onButtonClick}
        variant={ButtonVariants.PRIMARY}
        className="w-20 leading-5"
      >
        Upgrade
      </Button>
    </InfoBlock>
  );
};
