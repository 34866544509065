export enum AppRoutes {
  HOME = '/',
  BOOSTS = '/boosts',
  PERSON = '/person',
  INVITES_BONUS = '/invites-bonuses',
  ACTIVITY = '/activity',
  LEADERS = '/leaders',
  LUCKY_GAMES = '/activity/lucky-games',
  LUCKY_GAME = '/activity/lucky-games/:id'
}
