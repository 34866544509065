import React, { ReactNode } from 'react';

import { Typography } from './Typography';

interface BaseProps<T> {
  renderContent: (data: T) => ReactNode;
  isEmpty?: boolean;
  emptyContent?: string;
  data: T[];
}

interface LoadingWithSkeletonProps<T> extends BaseProps<T> {
  isLoading: boolean;
  skeleton: ReactNode;
}

interface NotLoadingWithoutSkeletonProps<T> extends BaseProps<T> {
  isLoading?: false;
  skeleton?: never;
}

type Props<T> = LoadingWithSkeletonProps<T> | NotLoadingWithoutSkeletonProps<T>;

export const WithLoadingWrapper = <T,>({
  data,
  renderContent,
  isLoading,
  skeleton,
  isEmpty,
  emptyContent
}: Props<T>) => {
  if (isLoading) return <>{skeleton}</>;

  if (isEmpty || (!isLoading && !data?.length))
    return <Typography className="text-center">{emptyContent || 'Not found'}</Typography>;

  return <>{data?.map((item) => renderContent(item))}</>;
};
