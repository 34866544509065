import { useMemo } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { setLuckyGameCombinationById } from '../../../model/lucky-games/action';
import {
  LotterySetCombinationPayload,
  LUCKY_GAME_TYPES,
  LuckyCombination
} from '../../../types/lucky-games.interfaces';
import { generateArrayOfOptionsByNumber } from '../../utils/array-generators';
import { useAppDispatch } from '../useAppDispatch';

const LINE_ELEMENTS_COUNTS = 8;

type LuckySelectNumbersInput = { [key: `row_${string}`]: string[] };

interface Props {
  totalSelectableNumbers: number;
  type: LUCKY_GAME_TYPES;
  combinations: LuckyCombination[];
}

export const useSetLuckyGameCombinations = ({
  totalSelectableNumbers,
  type,
  combinations
}: Props) => {
  const { id: luckyGameId } = useParams();

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue
  } = useForm<LuckySelectNumbersInput>();

  const formOptions = useMemo(() => {
    if (type === LUCKY_GAME_TYPES.GAME4) {
      const totalRows = totalSelectableNumbers / LINE_ELEMENTS_COUNTS;

      return Array.from({ length: totalRows }, (_, index) => ({
        id: `row_${index + 1}`,
        options: generateArrayOfOptionsByNumber(LINE_ELEMENTS_COUNTS),
        label: undefined
      }));
    }

    const bonusRow = {
      id: 'row_bonusNumber',
      options: generateArrayOfOptionsByNumber(LINE_ELEMENTS_COUNTS),
      label: 'Choose 1 bonus number'
    };

    return [
      {
        id: 'row_1',
        options: generateArrayOfOptionsByNumber(totalSelectableNumbers),
        label: undefined
      },
      ...(type === LUCKY_GAME_TYPES.GAME2 ? [bonusRow] : [])
    ];
  }, [totalSelectableNumbers, type]);

  const idOfEmptyCombination = useMemo(() => {
    const emptyCombination = combinations?.find(
      ({ selected_combination_numbers: selectedCombinationNumbers }) =>
        selectedCombinationNumbers.every((item) => item === null)
    );

    return emptyCombination?.id;
  }, [combinations]);

  const onSetCombination: SubmitHandler<LuckySelectNumbersInput> = (values) => {
    if (!idOfEmptyCombination || !luckyGameId)
      return toast.error('There are no available combinations');

    const { row_bonusNumber: bonusNumbers, ...restValues } = values;

    // Format Object to array with combination
    const preparedCombination = Object.values(restValues).flat().map(Number);

    const payload: LotterySetCombinationPayload = {
      id: luckyGameId,
      combinationId: idOfEmptyCombination,
      combination: preparedCombination
    };

    if (type === LUCKY_GAME_TYPES.GAME2) {
      payload.bonusNumber = Number(bonusNumbers[0]);
    }

    dispatch(setLuckyGameCombinationById(payload))
      .unwrap()
      .then(() => reset());
  };

  return { register, watch, reset, formOptions, handleSubmit, onSetCombination, setValue };
};
