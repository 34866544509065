import { useState } from 'react';

import { format } from 'date-fns';

import { AnimatedCheckmark, AnimatedErrorIcon } from 'entities/animated-icons';
import { LuckyGameCardCombinationNumbers } from 'entities/games';
import { LotteryResultCard } from 'entities/lottery-game-results/components/LotteryResultCard';
import {
  Button,
  checkGameResult,
  claimLotteryReward,
  GameResults,
  LUCKY_GAME_TYPES,
  Modal,
  Typography,
  useAppDispatch,
  useBoolean,
  WithTitleBlock
} from 'shared';
import { Loader } from 'shared/components/Loader';
import { DATE_FORMAT_MONTH_DAY } from 'shared/constants/date-formats';
import { TypographyVariants } from 'shared/constants/typography';
import { LoaderSizes } from 'shared/types/loader.interfaces';

import { GameResultModalContent } from './GameResultModalContent';
import { ReactComponent as WinningIcon } from '../../../assets/svg/winning.svg';

interface Props {
  date: string;
  gameData: GameResults[];
}

enum RequestStatusType {
  PENDING = 'pending',
  SUCCESSFULLY = 'successfully',
  ERROR = 'error'
}

const statusComponentByRequest = {
  [RequestStatusType.PENDING]: <Loader size={LoaderSizes.XL} />,
  [RequestStatusType.ERROR]: <AnimatedErrorIcon />,
  [RequestStatusType.SUCCESSFULLY]: <AnimatedCheckmark />
};

export const GameResultsBlock = ({ date, gameData }: Props) => {
  const formatDate = format(new Date(date), DATE_FORMAT_MONTH_DAY);

  const [requestStatus, setRequestStatus] = useState<RequestStatusType | null>(null);
  const [isLotteryResultdModalOpen, handleOpenLotteryResultModal, handleCloseLotteryResultModal] =
    useBoolean();
  const [
    isClaimRewardRequestdModalOpen,
    handleOpenClaimRewardRequestModal,
    handleCloseClaimRewardRequestModal
  ] = useBoolean();

  const dispatch = useAppDispatch();

  const checkWinningLottery = (id: string) => {
    dispatch(checkGameResult(id));
    handleOpenLotteryResultModal();
  };

  const claimReward = (lotteryId: string) => {
    setRequestStatus(RequestStatusType.PENDING);

    dispatch(claimLotteryReward(lotteryId))
      .unwrap()
      .finally(() => setRequestStatus(RequestStatusType.SUCCESSFULLY))
      .catch(() => setRequestStatus(RequestStatusType.ERROR));

    handleCloseLotteryResultModal();
    handleOpenClaimRewardRequestModal();
  };

  return (
    <div>
      <Modal
        header="Claim lottery reward"
        isOpened={isClaimRewardRequestdModalOpen}
        handleClose={handleCloseClaimRewardRequestModal}
        height={25}
      >
        <div className="h-full flex justify-center items-center">
          {requestStatus && statusComponentByRequest[requestStatus]}
        </div>
      </Modal>
      <Modal
        header={`Lottery result`}
        description={`Lottery result description`}
        isOpened={isLotteryResultdModalOpen}
        handleClose={handleCloseLotteryResultModal}
        height={75}
      >
        <GameResultModalContent claimReward={claimReward} />
      </Modal>
      <Typography className="mb-2" tag="h2" variant={TypographyVariants.LARGE_TITLE}>
        {formatDate}
      </Typography>
      <div className="bg-[#282B30] rounded-md py-2 px-1 mb-5 space-y-2">
        {gameData?.map(({ id, title, win_combinations }) => (
          <WithTitleBlock key={id} title={`Game ${title}`} childrenClassName="mt-1.5">
            <LotteryResultCard>
              <WinningIcon className="fill-white w-6 h-6" />
              <LuckyGameCardCombinationNumbers
                selectedNumbers={win_combinations}
                bonusNumber={null}
                type={LUCKY_GAME_TYPES.GAME1}
                className="flex-1"
              />
              <Button onClick={() => checkWinningLottery(id)} className="px-3 py-1 rounded-full">
                Check
              </Button>
            </LotteryResultCard>
          </WithTitleBlock>
        ))}
      </div>
    </div>
  );
};
