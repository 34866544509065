import { useEffect } from 'react';

import { BoostsList } from 'features/boosts';
import { asyncGetUpgrades, getTelegramApi, useAppDispatch, useAppSelector } from 'shared';
import { selectIsUpgradesLoading } from 'shared/model/boosters/selectors';
import { SkeletonBoostsList } from 'widgets/skeleton/components/boosts-list/SkeletonBoostsList';

export const Boosts = () => {
  const dispatch = useAppDispatch();

  const isUpgradesLoading = useAppSelector(selectIsUpgradesLoading);

  const tg = getTelegramApi();

  tg.BackButton.isVisible = true;

  useEffect(() => {
    dispatch(asyncGetUpgrades());
  }, [dispatch]);

  return (
    <>
      <h2 className="text-2xl font-bold text-center">BOOST BONUSES</h2>
      {isUpgradesLoading ? <SkeletonBoostsList /> : <BoostsList />}
    </>
  );
};
