import { selectLuckyGame, useAppSelector } from 'shared';
import { LuckyGameSkeleton } from 'widgets/skeleton';

import { LuckyGameArea, LuckyGameBuyTickets, LuckyGameCombinations } from '../../../features/games';

const LuckyGameWidget = () => {
  const { data, isGameLoading } = useAppSelector(selectLuckyGame);

  if (isGameLoading || !data) return <LuckyGameSkeleton />;

  return (
    <div className="space-y-3.5">
      <LuckyGameBuyTickets ticketPrice={data?.ticket_price} />
      <LuckyGameArea
        type={data?.type}
        totalSelectableNumbers={data?.total_selectable_numbers}
        combinations={data?.combinations}
      />
      <LuckyGameCombinations combinations={data?.combinations} type={data?.type} />
    </div>
  );
};

export default LuckyGameWidget;
