import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { selectLuckyGame, getLuckyGame } from '../../../index';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';

export const useLuckyGame = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const luckyGameData = useAppSelector(selectLuckyGame);

  useEffect(() => {
    if (!id) return;

    dispatch(getLuckyGame(id));
  }, [id, dispatch]);

  return { ...luckyGameData };
};
