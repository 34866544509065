import { createSlice } from '@reduxjs/toolkit';

import { LUCKY_GAMES_SLICE_NAME } from './action';
import {
  checkGameResultReducer,
  getLuckyGameReducer,
  getLuckyGameResultsReducer,
  getLuckyGamesReducer,
  setLuckyGameCombinationsReducer
} from './reducers';
import {
  GameResultResponce,
  GamesResultsResponse,
  LuckyGame,
  PartialLuckyGame
} from '../../types/lucky-games.interfaces';

export interface LuckyGamesSliceState {
  game: LuckyGame | null;
  games: PartialLuckyGame[];
  results: GamesResultsResponse | null;
  result: GameResultResponce | null;
  isGamesLoading: boolean;
  isResultsLoading: boolean;
  isResultLoading: boolean;
  isGameLoading: boolean;
}

export const initialState: LuckyGamesSliceState = {
  game: null,
  games: [],
  results: null,
  result: null,
  isGamesLoading: false,
  isResultsLoading: false,
  isResultLoading: false,
  isGameLoading: false
};

const luckyGamesSlice = createSlice({
  name: LUCKY_GAMES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getLuckyGamesReducer(builder);
    getLuckyGameReducer(builder);
    setLuckyGameCombinationsReducer(builder);
    getLuckyGameResultsReducer(builder);
    checkGameResultReducer(builder);
  }
});

export const luckyGamesReducer = luckyGamesSlice.reducer;
