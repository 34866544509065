import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { gamesApi } from 'shared/apis/games-api';
import { showServerError } from 'shared/lib/utils/modules';

export const GAMES_SLICE_NAME = 'games';

export const asyncGetGames = createAsyncThunk(
  `${GAMES_SLICE_NAME}/getGames`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await gamesApi.getGames();

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const setGameReward = createAction(`${GAMES_SLICE_NAME}/setGameReward`, (score) => ({
  payload: score
}));
