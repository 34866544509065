import { Skeleton } from 'widgets/skeleton/components/Skeleton';

const LuckyGamesSkeleton = () => {
  return (
    <div className="bg-[#282B30] rounded-xl p-4 gap-3">
      <div className="flex justify-end">
        <Skeleton className="w-20 h-6 rounded-lg" />
      </div>
      <div className="flex">
        <div className="w-4/12">
          <Skeleton className="w-20 h-20 rounded-xl" />
          <Skeleton className="mt-4 w-20 h-7 rounded-lg" />
        </div>

        <div className="w-8/12 flex flex-col justify-center items-center">
          <Skeleton className="w-[120px] h-5 rounded-md" />
          <Skeleton className="mt-1 w-36 h-7 rounded-md" />
          <Skeleton className="mt-1 w-[120px] h-7 rounded-md" />
        </div>
      </div>
    </div>
  );
};

export default LuckyGamesSkeleton;
