import { useNavigate } from 'react-router-dom';

import { clearLuckyGameCombinationById } from '../../../model/lucky-games/action';
import { LotteryClearCombinationPayload } from '../../../types/lucky-games.interfaces';
import { getLuckyGamePageUrl } from '../../utils/url';
import { useAppDispatch } from '../useAppDispatch';
import { useBoolean } from '../useBoolean';

export interface UseClearLuckyGameCombinationValues {
  isLoading: boolean;
  handleClearLuckyGameCombination: VoidFunction;
  handleOpenClearModal: (modalProps: LotteryClearCombinationPayload) => void;
  handleCloseClearModal: VoidFunction;
  isClearModalOpen: boolean;
  clearModalProps: LotteryClearCombinationPayload | null;
}

export const useClearLuckyGameCombination = (): UseClearLuckyGameCombinationValues => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, handleStartLoading, handleStopLoading] = useBoolean();
  const [isClearModalOpen, handleOpenClearModal, handleCloseClearModal, clearModalProps] =
    useBoolean<LotteryClearCombinationPayload>();

  const handleClearLuckyGameCombination = async () => {
    const id = clearModalProps?.id;

    if (!id) return;

    handleStartLoading();
    dispatch(clearLuckyGameCombinationById(clearModalProps))
      .unwrap()
      .then(() => {
        navigate(getLuckyGamePageUrl(id));
        handleCloseClearModal();
      })
      .finally(handleStopLoading);
  };

  return {
    isLoading,
    handleClearLuckyGameCombination,
    handleOpenClearModal,
    handleCloseClearModal,
    isClearModalOpen,
    clearModalProps
  };
};
