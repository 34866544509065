import { Skeleton } from './Skeleton';

export const SkeletonLeaderCard = () => {
  return (
    <div className="bg-[#282B30] rounded-xl flex px-4 py-1 gap-3">
      <Skeleton className="w-9 h-9 rounded-full" />

      <div className="flex-1 py-1 space-y-1">
        <Skeleton className="w-1/4 h-3 rounded-md" />
        <Skeleton className="w-1/2 h-3 rounded-md" />
      </div>
      <Skeleton className="w-9 h-9 rounded-md" />
    </div>
  );
};
