export enum TelegramInvoiceStatuses {
  PAID = 'paid',
  FAILED = 'failed', // TODO - double check this value
  CANCELLED = 'cancelled'
}

export enum HapticFeedbackStyles {
  LIGHT = 'light',
  MEDIUM = 'medium',
  HEAVY = 'heavy',
  RIGID = 'rigid',
  SOFT = 'soft'
}
