import { ReactNode } from 'react';

/* eslint-disable import/no-unresolved */
import { Pagination } from 'swiper/modules';
import { Swiper } from 'swiper/react';

const pagination = {
  clickable: true,
  bulletClass: 'swiper-pagination-bullet bullet_swiper',
  bulletActiveClass: 'bullet_swiper_active'
};

interface Props {
  children: ReactNode;
}

export const Slider = ({ children }: Props) => {
  return (
    <Swiper
      slidesPerView={2}
      spaceBetween={14}
      className="h-full pt-2.5"
      modules={[Pagination]}
      pagination={pagination}
    >
      {children}
    </Swiper>
  );
};
