import { useCallback, useEffect, useState } from 'react';

import { differenceInMilliseconds, parseISO } from 'date-fns';

import { useAppSelector, selectUser, selectServerTime, User } from 'shared';
import { UpgradeTypes } from 'shared/types/boosters.interfaces';

import { useGetCurrentUpgrade } from './useGetСurrentUpgrade';

const CLAIM_INCREASE_RATE = 1250;
const CLAIM_PERIOD_HOURS = 4;
const CLAIM_PERIOD_MILLISECONDS = CLAIM_PERIOD_HOURS * 60 * 60 * 1000;
const DOUBLE_CLAIM_MINUTES = 5;
const DOUBLE_CLAIM_MILLISECONDS = DOUBLE_CLAIM_MINUTES * 60 * 1000;
const MAX_CLAIM = 1250;

export const useAvailableClaim = () => {
  const [available, setAvailable] = useState(0);
  let boostClaim = 1;

  const user = useAppSelector(selectUser);
  const serverTime = useAppSelector(selectServerTime);

  const currentBoostClaim = useGetCurrentUpgrade(UpgradeTypes.DAILY_CLAIM);

  const checkBoost = useCallback((lastClaim: Date, currentTime: Date, user: User) => {
    let boost = 1;

    const currentTimeMilliseconds = currentTime.getTime();
    const fullClaimMilliseconds = lastClaim.getTime() + CLAIM_PERIOD_MILLISECONDS;
    const doubleClaimMilliseconds = fullClaimMilliseconds + DOUBLE_CLAIM_MILLISECONDS;

    boost = user.double_claim > 0 ? 2 : 1;

    if (
      currentTimeMilliseconds >= fullClaimMilliseconds &&
      currentTimeMilliseconds < doubleClaimMilliseconds
    )
      boost = boost * 2;

    return boost;
  }, []);

  useEffect(() => {
    if (!serverTime || !user?.last_claim || !currentBoostClaim) return;

    const serverTimeDate = parseISO(serverTime);
    const lastClaimDate = parseISO(user?.last_claim);

    const differenceInMs = differenceInMilliseconds(serverTimeDate, lastClaimDate);
    const differenceInHours = differenceInMs / (1000 * 60 * 60);

    const fullPeriods = Math.floor(differenceInHours / CLAIM_PERIOD_HOURS);
    const remainingHours = differenceInHours % CLAIM_PERIOD_HOURS;
    const proportionateIncrease = (remainingHours / CLAIM_PERIOD_HOURS) * CLAIM_INCREASE_RATE;

    boostClaim = checkBoost(lastClaimDate, serverTimeDate, user);

    let availableClaim = fullPeriods * CLAIM_INCREASE_RATE + proportionateIncrease;

    if (availableClaim > MAX_CLAIM) {
      availableClaim = MAX_CLAIM;
    }

    if (availableClaim < 0) {
      availableClaim = 0;
    }

    setAvailable(Math.floor(availableClaim) * currentBoostClaim.multiplier * boostClaim);
  }, [currentBoostClaim, serverTime, user, checkBoost]);

  return [available, boostClaim];
};
