import { SkeletonGameCard } from './SkeletonGameCard';

export const SkeletonGamesList = () => {
  return (
    <div className="space-y-1.5 mt-8">
      {Array.from(Array(4).keys()).map((key) => (
        <SkeletonGameCard key={key} />
      ))}
    </div>
  );
};
