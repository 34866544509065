import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { lotteryApi } from '../../apis/lottery-api';
import { BuyLuckyGameTicketsModalProps } from '../../lib/hooks/games/useBuyLuckyGameTickets';
import { showServerError } from '../../lib/utils/modules';
import {
  LotteryClearCombinationPayload,
  LotterySetCombinationPayload,
  LuckyCombination
} from '../../types/lucky-games.interfaces';

export const LUCKY_GAMES_SLICE_NAME = 'lucky-games';

export const getLuckyGames = createAsyncThunk(
  `${LUCKY_GAMES_SLICE_NAME}/getLuckyGames`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await lotteryApi.getLotteries();

      return response.data.lotteries;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const getLuckyGame = createAsyncThunk(
  `${LUCKY_GAMES_SLICE_NAME}/getLuckyGame`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await lotteryApi.getLotteryById(id);

      return response.data.lottery;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const buyLuckyGameAccess = createAsyncThunk(
  `${LUCKY_GAMES_SLICE_NAME}/buyLuckyGameAccess`,
  async (id: string, { rejectWithValue }) => {
    try {
      await lotteryApi.buyLotteryAccessById(id);
      toast.success('You have successfully joined the game');
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const buyLuckyGameTickets = createAsyncThunk(
  `${LUCKY_GAMES_SLICE_NAME}/buyLuckyGameTickets`,
  async (payload: Omit<BuyLuckyGameTicketsModalProps, 'price'>, { dispatch, rejectWithValue }) => {
    try {
      const response = await lotteryApi.buyLotteryTickets(payload);

      const { combinations } = response?.data;

      toast.success(`Successfully paid`);

      dispatch(setLuckyGameCombinations(combinations));
      return combinations;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const setLuckyGameCombinationById = createAsyncThunk(
  `${LUCKY_GAMES_SLICE_NAME}/setLuckyGameCombinationById`,
  async (payload: LotterySetCombinationPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await lotteryApi.setLotteryCombinationById(payload);

      const { combinations } = response?.data;

      toast.success(`Combination was successfully added.`);

      dispatch(setLuckyGameCombinations(combinations));

      return combinations;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const clearLuckyGameCombinationById = createAsyncThunk(
  `${LUCKY_GAMES_SLICE_NAME}/clearLuckyGameCombinationById`,
  async (payload: LotteryClearCombinationPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await lotteryApi.clearLotteryCombinationById(payload);

      const { combinations } = response?.data;

      toast.success(`Combination was successfully cleared.`);

      dispatch(setLuckyGameCombinations(combinations));

      return combinations;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const setLuckyGameCombinations = createAction<LuckyCombination[]>(
  'SET_LUCKY_GAME_COMBINATIONS'
);

//Lucky-game results

export const getLuckyGamesResults = createAsyncThunk(
  `${LUCKY_GAMES_SLICE_NAME}/getLuckyGamesResults`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await lotteryApi.getLotteriesResults();

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const checkGameResult = createAsyncThunk(
  `${LUCKY_GAMES_SLICE_NAME}/checkWinningCombinations`,
  async (lotteryId: string, { rejectWithValue }) => {
    try {
      const response = await lotteryApi.checkLottery(lotteryId);

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const claimLotteryReward = createAsyncThunk(
  `${LUCKY_GAMES_SLICE_NAME}/claimLotteryReward`,
  async (lotteryId: string, { rejectWithValue }) => {
    try {
      await lotteryApi.claimLottery(lotteryId);
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);
