import { Skeleton } from '../Skeleton';

export const SkeletonGameCard = () => {
  return (
    <div className="bg-[#282B30] rounded-xl flex px-5 py-2 gap-3 items-center">
      <Skeleton className="w-8 h-8 rounded-md" />
      <div className="flex-1 space-y-1">
        <Skeleton className="w-4/5 h-3 rounded-md" />
        <Skeleton className="w-1/3 h-3 rounded-md" />
      </div>
      <Skeleton className="w-1/4 h-4 rounded-md" />
    </div>
  );
};
