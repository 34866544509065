import { SVGComponent } from 'shared/types/svg.interfaces';

import { ReactComponent as FlappyBirdIcon } from '../../assets/svg/activity/flappy-bird.svg';
import { ReactComponent as LuckyGamesIcon } from '../../assets/svg/activity/lottery.svg';
import { ReactComponent as SnakeIcon } from '../../assets/svg/activity/snake.svg';
import { ReactComponent as Game2048Icon } from '../../assets/svg/activity/tiles.svg';

export enum GameStatus {
  DEFAULT = 'default',
  LOST = 'lost',
  WON = 'won'
}

export enum Games {
  SNAKE = 'snake',
  GAME_2048 = '2048',
  FLAPPY_BIRD = 'flappy-bird',
  LUCKY_GAMES = 'lucky-games'
}

export const variantGameStatus = {
  [GameStatus.LOST]: {
    heading: 'You lost!',
    buttonText: 'Restart',
    blockClassName: 'bg-red-500/80'
  },
  [GameStatus.WON]: {
    heading: 'You won!',
    buttonText: 'Restart',
    blockClassName: 'bg-green-400/80'
  },
  [GameStatus.DEFAULT]: {
    heading: '',
    buttonText: 'Start game!',
    blockClassName: 'bg-black/50',
    buttonClassName: 'rounded-full'
  }
};

export const GAMES_ICONS: { [key in Games]: SVGComponent } = {
  [Games.SNAKE]: SnakeIcon,
  [Games.GAME_2048]: Game2048Icon,
  [Games.FLAPPY_BIRD]: FlappyBirdIcon,
  [Games.LUCKY_GAMES]: LuckyGamesIcon
};
