import { createSlice } from '@reduxjs/toolkit';

import { GamesPayload } from 'shared/types/games.interfaces';

import { GAMES_SLICE_NAME } from './action';
import { getGamesReducer, setGameRewardReducer } from './reducers';

export interface GamesSliceState {
  games: GamesPayload[] | null;
  isLoading: boolean;
  gameReward: number;
}

export const initialState: GamesSliceState = {
  games: null,
  isLoading: true,
  gameReward: 0
};

const gamesSlice = createSlice({
  name: GAMES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getGamesReducer(builder);
    setGameRewardReducer(builder);
  }
});

export const gamesReducer = gamesSlice.reducer;
