import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { Option } from '../../types/form-elements.interfaces';

interface Props {
  options: Option[];
  renderContent: (data: Option) => ReactNode;
  label?: string;
  className?: string;
}

export const SwitchableInputsGroup = ({ label, options, renderContent, className }: Props) => {
  return (
    <fieldset>
      {label && <p className="font-medium text-white text-md mb-4">{label}</p>}
      <div className={twMerge('flex flex-wrap gap-3.5', className)}>
        {options?.map((option) => renderContent(option))}
      </div>
    </fieldset>
  );
};
