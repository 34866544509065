import { createSlice } from '@reduxjs/toolkit';

import { BOOSTS_SLICE_NAME } from './action';
import { getUpgradesReducer } from './reducers';
import { Upgrade, UpgradeTypes } from '../../types/boosters.interfaces';

export interface BoostersSliceState {
  upgrades: Record<UpgradeTypes, Upgrade> | null;
  isLoading: boolean;
}

export const initialState: BoostersSliceState = {
  upgrades: null,
  isLoading: true
};

const boostersSlice = createSlice({
  name: BOOSTS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getUpgradesReducer(builder);
  }
});

export const boostersReducer = boostersSlice.reducer;
