/**
 * Game Layout
 */
const WINDOW_SIZE = window.innerWidth;

export const BLOCK_SIZE = WINDOW_SIZE - 54;

export const TILE_COUNT_PER_DIMENSION = 4;

export const ITEM_SIZE = (BLOCK_SIZE - 48) / 4;

/**
 * Animations
 */
export const mergeAnimationDuration = 100; // ms

export const moveAnimationDuration = 200; // ms

/**
 * Game setup
 */
export const gameWinTileValue = 2048;
